import { Box } from "@material-ui/core";
import { Button, Typography } from "@mui/material";
import React, { forwardRef } from "react";
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import DateRangeIcon from '@mui/icons-material/DateRange';
import { useState } from "react";
import { addDays, isBefore } from 'rsuite/esm/utils/dateUtils';
import { memo } from "react";

const DatePickerComponent = ({ handleUpdate, isRangeSelector, initialValue = new Date() }) => {
    const [singleStartDate, setSingleStartDate] = useState(initialValue ? new Date(initialValue) : null);

    const [seriesStartDate, setSeriesStartDate] = useState(isRangeSelector && initialValue ? new Date(initialValue[0]) : initialValue  ? new Date(initialValue) : null);
    const [seriesEndDate, setSeriesEndDate] = useState(isRangeSelector && initialValue ? new Date(initialValue[1]) : null);
    const [defaultMonth, setDefaultMonth] = useState(1);
    const [endDate, setEndDate] = useState(null);
    const [highlightedDate, setHighlightedDate] = useState([]);

    const handleSingleChange = (newValue) => {
        setSingleStartDate(newValue);
        handleUpdate(newValue)
    };

    const handleRangeChange = (dates) => {
        const [start, end] = dates;
        if (end) {
            handleUpdate(dates)
        }

        if (!(start || end)) {
            setSeriesStartDate(null);
            setSeriesEndDate(null);
            setDefaultMonth(1)
            setEndDate(null)
            handleUpdate(null)
            return
        }

        const endDateOffset = addDays(start, 29);
        const dateRange = [{ start: start, end: endDateOffset }].slice()
        setEndDate(dateRange)
        if (endDateOffset) {
            setDefaultMonth(((endDateOffset?.getMonth() > start?.getMonth()) || endDateOffset.getYear() > start.getYear()) ? 2 : 1)
            let datesList = [];

            for (let i = 0; i < 30; i++) {
                const newDate = addDays(start, i);
                if (isBefore(new Date(), newDate)) break;
                datesList.push(newDate)
            }
            setHighlightedDate(datesList.slice())
        }

        setSeriesStartDate(start);
        setSeriesEndDate(end);
    };

    const InputBtn = forwardRef(({ value, onClick }, ref) => {
        return (
            <Box onClick={onClick} ref={ref}>
                <Button variant="outlined" size="small" endIcon={<DateRangeIcon />} sx={{
                    maxWidth: '150px'
                }} className='calender-btn'>
                    <Typography variant="subtitle2" color="text.primary" sx={{
                        textTransform: 'none'
                    }}>
                        {value || 'Select dates'}
                    </Typography>
                </Button>
            </Box>
        )
    });
    return (
        <Box>
            {isRangeSelector ?
                <DatePicker
                    selected={seriesStartDate}
                    selectsRange
                    onChange={handleRangeChange}
                    showMonthDropdown
                    showYearDropdown
                    dropdownMode="select"
                    fixedHeight
                    startDate={seriesStartDate}
                    endDate={seriesEndDate}
                    openToDate={seriesEndDate}
                    maxDate={new Date()}
                    monthsShown={defaultMonth}
                    calendarStartDay={1}
                    customInput={<InputBtn />}
                    dateFormat="dd MMM yyyy"
                    isClearable
                    onCalendarClose={()=>{
                        if(seriesStartDate && !seriesEndDate) {
                            handleRangeChange([seriesStartDate, seriesStartDate])
                        }
                    }}
                    {...(endDate && { includeDateIntervals: endDate, highlightDates: highlightedDate })}
                /> : <DatePicker
                    selected={singleStartDate}
                    dateFormat="dd MMM yyyy"
                    maxDate={new Date()}
                    customInput={<InputBtn />}
                    onChange={handleSingleChange}
                    peekNextMonth
                    showMonthDropdown
                    showYearDropdown
                    dropdownMode="select"
                    isClearable
                />}
        </Box>
    );
}

export default memo(DatePickerComponent);
