import { Box, Checkbox, FormControl, FormControlLabel, Grid, Paper, Radio, RadioGroup, Stack, TextField, Typography } from "@mui/material";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { defaultQuestion, Global, ccDefaultQuestion } from "../../global";
import { saveAllQuestions, saveComputeData } from "../../redux/actions/appActions";
import { styled } from '@mui/material/styles';
import DatePickerComponent from "./DatePickerComponent";
import { useState } from "react";
import { Map } from "immutable";

const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'center',
    height: 150,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    position: 'relative',
    color: theme.palette.text.primary,
}));

const StaticComponent = ({ questionKey, questionKey1, questionText, subText, answerType, handleQuestion, isPinkBackground }) => {

    const dispatch = useDispatch();
    const currentQuestionData = useSelector(state => state.appReducer[questionKey]);

    const questionData = Map.isMap(currentQuestionData) ? currentQuestionData.toJS() : currentQuestionData;
    const currentQuestionData1 = useSelector(state => questionKey1 ? state.appReducer[questionKey1] : '');

    const questionData1 = Map.isMap(currentQuestionData1) ? currentQuestionData1.toJS() : currentQuestionData1;

    const [textValue, setTextValue] = useState(questionData);
    const [gValue, setGValue] = React.useState(questionData1);
    return (
        <Box className="stack-container">
            <Grid container spacing={2}
                direction="row"
                justifyContent="center"
                alignItems="center">
                <Grid item xs={12} zeroMinWidth >
                    <Item style={{ background: isPinkBackground ? '#FE90AA' : '#FA6C6C' }}>
                        <Box>
                            <Typography variant="h5" >
                                {questionText}
                            </Typography>
                            {subText && <Typography variant='subtitle1'>
                                {subText}
                            </Typography>
                            }
                        </Box>
                    </Item>
                </Grid>
                <Grid item xs={12}>
                    <Box sx={{ width: '100%' }}>
                        {answerType == 'date' ? (
                            <Stack spacing={2} >
                                <Item style={{
                                    background: '#ebebeb',
                                    display: 'flex',
                                    justifyContent: 'space-evenly',
                                    flexDirection: 'column'
                                }}>
                                    <DatePickerComponent handleUpdate={(value) => {
                                        // ccDefaultQuestion[questionKey] = value;
                                        dispatch(saveAllQuestions(questionKey, value));
                                        handleQuestion(questionKey, value?.toString());
                                        dispatch(saveComputeData());
                                    }} initialValue={currentQuestionData} />
                                    <FormControl component="div" sx={{
                                        position: 'relative',
                                        zIndex: 0
                                    }}>
                                        <RadioGroup aria-label="gender" name={questionKey} value={gValue} onChange={(e) => {
                                            setGValue(e.target.value)
                                            // ccDefaultQuestion[questionKey1] = e.target.value;
                                            handleQuestion(questionKey1, e.target.value);
                                            dispatch(saveAllQuestions(questionKey1, e.target.value))
                                        }} sx={{
                                            flexDirection: 'row',
                                            justifyContent: 'space-around',
                                        }}>
                                            <FormControlLabel value="F" control={<Radio />} label="Biological Female" />
                                            <FormControlLabel value="M" control={<Radio />} label="Biological Male" />
                                        </RadioGroup>
                                    </FormControl>
                                </Item>
                            </Stack>
                        ) : (
                            <Stack spacing={2} >
                                <Item style={{
                                    background: '#ebebeb',
                                    height: 70,
                                    display: 'flex',
                                    justifyContent: 'flex-start'
                                }}>
                                    <FormControl fullWidth sx={{ m: 1 }}>
                                        <TextField
                                            label="Enter the details"
                                            variant="outlined"
                                            value={textValue}
                                            onChange={(event) => {
                                                // ccDefaultQuestion[questionKey1] = event.target.value;
                                                setTextValue(event.target.value);
                                                handleQuestion(questionKey, event.target.value);
                                            }}
                                            onBlur={(e) => {
                                                dispatch(saveAllQuestions(questionKey, textValue))
                                            }}
                                        />
                                    </FormControl>
                                </Item>
                            </Stack>)}
                    </Box>
                </Grid>
            </Grid>
        </Box>
    );
}

export default React.memo(StaticComponent);
