import { actionTypes } from '../constants/actionTypes'
import { fromJS, List, Map } from 'immutable'
import { computeProgressPercent } from '../Helper'
import { clone } from 'lodash'
import { omit } from 'lodash'

const initState = {
    agreeement: false,
    ccAgreeement: false,
    showOptionalQuestions: false,
    computeValue: 0,
    purchaseReports: [],
    trialReports: [],
    naQuestions: {},
    affiliatorCode: '',
    showSummary: false,
    person1_quiz_data: List([]),
    person2_quiz_data: List([])
}
export const appReducer = (state = initState, { type, payload }) => {
    switch (type) {
        case actionTypes.SAVE_QUESTIONS:
            return { ...state, [payload.key]: fromJS(payload.data) }

        case actionTypes.SAVE_DYNAMIC_QUESTIONS:
            return { ...state, [payload.key]: state[payload.key].concat(payload.data) }

        case actionTypes.DELETE_DYNAMIC_QUESTIONS:
            localStorage.setItem("quizdata", JSON.stringify(omit(JSON.parse(localStorage.getItem("quizdata")), ['person1_quiz_data', 'person2_quiz_data'])));
            return { ...state, person1_quiz_data: List([]), person2_quiz_data: List([]) }

        case actionTypes.UPDATE_DYNAMIC_QUESTIONS:
            state[payload.key].map(question => {
                if (question.qid === payload.data.qid) {
                    question.answer = payload.data.answer
                }
            })
            return { ...state }

        case actionTypes.SAVE_PERSON1_QUESTIONS:
            return { ...state, person1_quiz_data: state.person1_quiz_data.concat(payload.data) }

        case actionTypes.SAVE_PERSON2_QUESTIONS:
            return { ...state, person1_quiz_data: state.person1_quiz_data.concat(payload.data) }

        case actionTypes.SAVE_NA_QUESTIONS:
            return { ...state, naQuestions: {...state.naQuestions, [payload.key]: payload.data} }

        case actionTypes.SAVE_PURCHASE_REPORTS_DATA:
            return { ...state, purchaseReports: fromJS(payload) }

        case actionTypes.UPDATE_PURCHASE_REPORTS_DATA:
            const updatedPurchaseReports = state.purchaseReports.map( r => {
                if ((r.get('category') == payload.catagoryName) || payload.catagoryName == 'all_category') {
                    return r.set('subcategories', r.get('subcategories').map(s => {
                        if((payload.subCatagoryName == 'Hpwq' && s.get('code') == 'Hp') || payload.subCatagoryName == 'Hp' && s.get('code') == 'Hpwq') {
                            return s.set('selected', false)
                        }
                        if (s.get('code') == payload.subCatagoryName) {
                            return s.set('selected', payload.subCatagoryValue)
                        }
                        return s;
                    }))
                }
                return r;
            });
            return { ...state, purchaseReports: updatedPurchaseReports }

        case actionTypes.SAVE_TRIAL_REPORTS_DATA:
            return { ...state, trialReports: fromJS(payload) }

        case actionTypes.SAVE_MAINTENANCE_DATA:
            return { ...state, maintenance: fromJS(payload) }

        case actionTypes.SAVE_ASSESSMENT_AGREEMENT:
            return { ...state, agreeement: payload }

        case actionTypes.SAVE_COUPLE_COMPATIBILITY_AGREEMENT:
            return { ...state, ccAgreeement: payload }

        case actionTypes.TOGGLE_OPTIONAL_QUESTIONS:
            return { ...state, showOptionalQuestions: payload }

        case actionTypes.SAVE_TRIAL:
            return { ...state, trialSelected: payload }

        case actionTypes.SAVE_AFFILIATOR_CODE:
            return { ...state, affiliatorCode: payload }

        case actionTypes.SAVE_COMPUTATION:
            return { ...state, computeValue: computeProgressPercent(state, state.naQuestions) }

        case actionTypes.SAVE_QUESTIONS_TO_LOCAL:
            localStorage.setItem("questionNotApplicableData", JSON.stringify(state.naQuestions));
            const stateCopy= clone(state)
            localStorage.setItem("quizdata", JSON.stringify(omit(stateCopy, ['computeValue', 'naQuestions', 'agreeement', 'purchaseReports', 'trialReports', 'showOptionalQuestions'])));
            
            return state

        case actionTypes.SAVE_STATIC_QUESTION:
            return state;

        default:
            return state;
    }
}
