// ▽
//

// const SERVER = 'http://localhost:5000'
//

const Global = {
  ccQuestion: {
    person1_name: "person1_name",
    person1_gender: "person1_gender",
    person1_dob: "person1_dob",
    person1_geo: "person1_geo",
    person1_pob: "person1_pob",
    person1_group_I: "person1_group_I",
    person1_group_II: "person1_group_II",
    person1_quiz_data: "person1_quiz_data",
    person2_name: "person2_name",
    person2_gender: "person2_gender",
    person2_dob: "person2_dob",
    person2_geo: "person2_geo",
    person2_pob: "person2_pob",
    person2_group_I: "person2_group_I",
    person2_group_II: "person2_group_II",
    person2_quiz_data: "person2_quiz_data",
    showSummary: "showSummary"
  },

  question: {
    dob: "dob",
    pob: "pob",
    geo: "geo",

    parent: 'parent',
    relatives: 'relatives',
    goodTime: 'goodTime',
    badTime: 'badTime',
    eduStartTime: 'eduStartTime',
    eduEndTime: 'eduEndTime',
    significantTime: 'significantTime',
    wealthTime: 'wealthTime',
    initalBuy: 'initalBuy',
    relocation: 'relocation',
    physicalHealth: 'physicalHealth',
    mentalHealth: 'mentalHealth',

    Mdb: "Mdb",
    Fdb: "Fdb",
    Brdb: "Brdb",
    Srdb: "Srdb",
    Sodb: "Sodb",
    Ddb: "Ddb",
    Spdb: "Spdb",
    Frdb: "Frdb",

    PLgr: "PLgr",
    PLbr: "PLbr",
    Wbv: "Wbv",
    Wbh: "Wbh",
    Rl: "Rl",
    Eds: "Eds",
    Ede: "Ede",
    Hp: "Hp",
    Hm: "Hm",
    Cs: "Cs",
    Csb: "Csb",
    Wn: "Wn",
    Wo: "Wo",

    CarColor: "CarColor",
    FavTaste: "FavTaste",
    ProfitDirection: "ProfitDirection",
    TravelFrequency: "TravelFrequency",
    PlaceType: "PlaceType",
    RomanticPartnerType: "RomanticPartnerType",
    MotherType: "MotherType",
    CareerType: "CareerType",
    DayNight: "DayNight",
    WeakBodyPart: "WeakBodyPart",
    EducationField: "EducationField",
    PersonalLife: "PersonalLife",
    PeopleRelationship: "PeopleRelationship",
    HealthIssues: "HealthIssues",
    CareerIndustry: "CareerIndustry",
    Gender: "Gender",
  },

  questionDescription: {
    dob: "Date of Birth",
    pob: "Place of Birth",
    geo: "Geo",

    Mdb: 'Biological Mother',
    Fdb: 'Biological Father',
    Brdb: 'Brother(s) with the same birth parents',
    Srdb: 'Sister(s) with the same birth parents',
    Sodb: 'Biological Son(s)',
    Ddb: 'Biological Daughter(s)',
    Spdb: 'Partner(s)',
    Frdb: 'Friends',

    PLgr: 'Good day date range',
    PLbr: 'Bad days date range',
    Wbv: 'Vehicle purchase date range',
    Wbh: 'Initial payment date range',
    Rl: 'Relocation date range',
    Eds: 'Education start date range',
    Ede: 'Graduation end date range',
    Hp: 'Physical health issues date range',
    Hm: 'Mental health issues date range',
    Cs: 'Success date range',
    Csb: 'Setback date range',
    Wn: 'Income date range',
    Wo: 'Wealth loss date range',

    CarColor: "Car Color",
    FavTaste: "Favorite Taste",
    ProfitDirection: "Profit Direction",
    TravelFrequency: "Travel Frequency",
    PlaceType: "Place Type",
    RomanticPartnerType: "Romantic Partner Type",
    MotherType: "Mother Type",
    CareerType: "Career Type",
    DayNight: "Day Night",
    WeakBodyPart: "Weak Body Part",
    EducationField: "Education Field",
    PersonalLife: "Personal Life",
    PeopleRelationship: "People Relationship",
    HealthIssues: "Health Issues",
    CareerIndustry: "Career Industry",
  },

  categorySortOrder: {
    RELATIONSHIPS: 2,
    WEALTH: 4,
    HEALTH: 1,
    CAREER: 3,
  },

  subcategorySortOrder: {
    rnms: 10,
    rbr: 11,
    rgr: 12,
    Wn: 20,
    Wo: 21,
    Hp: 31,
    Hpwq: 32,
    Hpi: 33,
    Ha: 34,
    Hm: 35,
    Cs: 41,
    Csb: 42,
  },

  subHeadings: {
    goodTime: ['Avoid events that did not have a tangible impact on your life.'],
    significantTime: ['a. Success (A new job, Promotion, Awards, Fame etc.)', 'b. Setbacks (Retrenchment, Pay Cut, Unpopularity etc.)'],
    wealthTime: ['a. Income (Pay raise, Investment portfolio value increase, Lottery win etc.)', 'b. Loss (Investment loss, Pay Cut etc)'],
    badTime: ['Avoid events that did not have a tangible impact on your life.'],
    initalBuy: ['a. Your Motor Vehicles? (Car, Motorbike, Scooter, Van etc)', 'b. Your Property? (Date of initial payment)']
  },

  subcategorySampleReport: {
    rnms: "/sample/buddynow_singlenomore.pdf",
    rbr: "/sample/buddynow_bad_relationship.pdf",
    rgr: "/sample/buddynow_good_relationship.pdf",
    Wn: "/sample/buddynow_wealth_income.pdf",
    Wo: "/sample/buddynow_wealth_loss.pdf",
    Hp: "/sample/buddynow_physical_health.pdf",
    Hpwq: "/sample/buddynow_physical_health_with_specified_illnesses.pdf",
    rnmswq: "/sample/buddynow_singlenomore_with_characteristics.pdf",
    Hm: "/sample/buddynow_mental_health.pdf",
    Hpi: "/sample/buddynow_physical_harm.pdf",
    Ha: "/sample/buddynow_accident.pdf",
    Cs: "/sample/buddynow_career_success.pdf",
    Csb: "/sample/buddynow_challenges_in_career.pdf",
    cc: "/sample/Couple_Compatibility_Report.pdf"
  },

  NoneOfTheAbove: "None of the above",
  BiologicalMale: "Biological Male",
  BiologicalFemale: "Biological Female"
};

function capitalizeFirstLetter(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

function getOptionsObj(vals, separator, skipsort,lastTxt) {
  let o = {};
  if (!separator) separator = ","; // default csv
  var txts = vals.split(separator).map((x) => capitalizeFirstLetter(x.trim()));
  if (!skipsort) { txts.sort(); }
  if(lastTxt){
    txts.push(capitalizeFirstLetter(lastTxt.trim()))
  }
  txts.forEach((x) => { o[x] = false; })
  return o;
}

function getBlankDateArray() {
  return Array(10).fill(null);
}

function ccDefaultQuestion() {
  const gq = Global.ccQuestion;
  let question = {};

  question[gq.person1_dob] = null;
  question[gq.person1_gender] = null;
  question[gq.person1_name] = null;
  question[gq.person1_geo] = [0, 0];
  question[gq.person1_pob] = null;
  question[gq.person1_quiz_data] = [];
  question[gq.person1_group_I] = null;
  question[gq.person1_group_II] = null;
  question[gq.person2_dob] = null;
  question[gq.person2_gender] = null;
  question[gq.person2_name] = null;
  question[gq.person2_geo] = [0, 0];
  question[gq.person2_pob] = null;
  question[gq.person2_quiz_data] = [];
  question[gq.person2_group_I] = null;
  question[gq.person2_group_II] = null;
  question[gq.showSummary] = false;
  return question;
}

function defaultQuestion() {
  const gq = Global.question;
  let question = {};
  // question[gq.dob] = "";
  // question[gq.pob] = "";
  // question[gq.Mdb] = "";
  // question[gq.Fdb] = "";
  // question[gq.Brdb] = ["", "", ""];
  // question[gq.Srdb] = ["", "", ""];
  // question[gq.Sodb] = ["", "", ""];
  // question[gq.Ddb] = ["", "", ""];
  // question[gq.Spdb] = ["", "", ""];
  // question[gq.Frdb] = ["", "", ""];
  // question[gq.PLgr] = ["", "", ""];
  // question[gq.PLbr] = ["", "", ""];
  // question[gq.Wbv] = ["", "", ""];
  // question[gq.Wbh] = ["", "", ""];
  // question[gq.Rl] = ["", "", ""];
  // question[gq.Eds] = ["", "", ""];
  // question[gq.Ede] = ["", "", ""];
  // question[gq.Hp] = ["", "", ""];
  // question[gq.Hm] = ["", "", ""];
  // question[gq.Cs] = ["", "", ""];
  // question[gq.Csb] = ["", "", ""];
  // question[gq.Wn] = ["", "", ""];
  // question[gq.Wo] = ["", "", ""];
  question[gq.dob] = null;
  question[gq.pob] = null;
  question[gq.parent] = {
    [gq.Mdb]: null,
    [gq.Fdb]: null
  }
  question[gq.relatives] ={
    [gq.Brdb]: getBlankDateArray(),
    [gq.Srdb]: getBlankDateArray(),
    [gq.Sodb]: getBlankDateArray(),
    [gq.Ddb]: getBlankDateArray(),
    [gq.Spdb]: getBlankDateArray(),
    [gq.Frdb]: getBlankDateArray()
  }
  question[gq.goodTime] = {
    [gq.PLgr]: getBlankDateArray()
  }
  question[gq.badTime] = {
    [gq.PLbr]: getBlankDateArray()
  }

  question[gq.eduEndTime] = {
    [gq.Ede]: getBlankDateArray()
  }

  question[gq.eduStartTime] = {
    [gq.Eds]: getBlankDateArray()
  }

  question[gq.significantTime] = {
    [gq.Cs]: getBlankDateArray(),
    [gq.Csb]: getBlankDateArray()
  }

  question[gq.wealthTime] = {
    [gq.Wn]: getBlankDateArray(),
    [gq.Wo]: getBlankDateArray()
  }

  question[gq.initalBuy] ={
    [gq.Wbv]: getBlankDateArray(),
    [gq.Wbh]: getBlankDateArray()
  }
  question[gq.relocation] = {
    [gq.Rl]: getBlankDateArray()
  }
  question[gq.physicalHealth] = {
    [gq.Hp]: getBlankDateArray()
  }
  question[gq.mentalHealth] = {
    [gq.Hm]: getBlankDateArray()
  }
  
  question[gq.Mdb] = null;
  question[gq.Fdb] = null;
  question[gq.Brdb] = getBlankDateArray();
  question[gq.Srdb] = getBlankDateArray();
  question[gq.Sodb] = getBlankDateArray();
  question[gq.Ddb] = getBlankDateArray();
  question[gq.Spdb] = getBlankDateArray();
  question[gq.Frdb] = getBlankDateArray();
  question[gq.PLgr] = getBlankDateArray();
  question[gq.PLbr] = getBlankDateArray();
  question[gq.Wbv] = getBlankDateArray();
  question[gq.Wbh] = getBlankDateArray();
  question[gq.Rl] = getBlankDateArray();
  question[gq.Eds] = getBlankDateArray();
  question[gq.Ede] = getBlankDateArray();
  question[gq.Hp] = getBlankDateArray();
  question[gq.Hm] = getBlankDateArray();
  question[gq.Cs] = getBlankDateArray();
  question[gq.Csb] = getBlankDateArray();
  question[gq.Wn] = getBlankDateArray();
  question[gq.Wo] = getBlankDateArray();
  question[gq.geo] = [0, 0];
  question[gq.CarColor] = getOptionsObj("White,Blue,Black,Silver,Orange,Red,Yellow,Rose,Maroon,Violet,Green",",",false,Global.NoneOfTheAbove);
  question[gq.FavTaste] = getOptionsObj("Pungent,Salty,Bitter,Sweet,Sour,Dry",",",false,Global.NoneOfTheAbove);
  question[gq.ProfitDirection] = getOptionsObj("North,North-East,East,South-East,South,South-West,West,North-West", ",", true,"Others");
  question[gq.TravelFrequency] = getOptionsObj("Frequently (eg: Once every 3 months); Moderately (eg: Once a year); Very rarely (eg: Once every 3 years)", ";", true, "Never");
  question[gq.DayNight] = getOptionsObj("Day,Night");
  question[gq.CareerType] = getOptionsObj("Top level management;People relationship; Mid-level management; Intellect, logic, reasoning; Deep knowledge, research; Creativity; Hardwork; Deception; Withdrawn, solitary", ";", false, Global.NoneOfTheAbove);
  question[gq.WeakBodyPart] = getOptionsObj('ankles,arms,blood,bone marrow,bones,brain,calves,chest,digestive system,eyes,face,feet,head,heart,hips,kidney,knees,liver,lower abdomen,lower back,lungs,mid back,mouth,muscles,neck,nerves,nose,private parts,reproductive system,respiratory system,skin,stomach,thighs,throat,toe,upper abdomen,upper back,whole body,excretory organs,Gall bladder,thyroid,lungs,joints',",",false,Global.NoneOfTheAbove);
  question[gq.PlaceType] = getOptionsObj("forest; plains; high altitude;high rise; riverside;lakeside; mountains;lush gardens;orchards; busy;commercial;downtown; private;hidden; royal; marsh lands;swamps;watery; mountain springs; seaside;oceanside;beach", ";",false,Global.NoneOfTheAbove);
  question[gq.EducationField] = getOptionsObj("Language and Literature Studies,Law studies,Religious Studies,Painting and Drawing,Music and Singing,Oratory(Speaking),Pharmaceutical/Medicine Making,Medical/Dental Studies ,Chemistry,Physics,Biology,Mechanical Engineering,Electrical/Electronic Engineering,Civil Engineering,Aerospace Engineering,Computer Engineering/IT ,Sociology(Study of Society),Psychology,History ,Agricultural Studies,Geography,Technical Hands-on Skills/Sculpting,Economics,Banking and Finance,Accountancy,Mathematics,Philosophy ,Business,Politics",",",false,Global.NoneOfTheAbove);
  question[gq.PersonalLife] = getOptionsObj("My marriage is stable, peaceful and happy;I benefitted financially because of divorce;I married a good spouse;My sex life is good;I became wealthy due to my spouse;I have a romantic boy/girlfriend;My boy/girlfriend is of good character; My boy/girlfriend is good looking; I am single", ";", false, Global.NoneOfTheAbove);
  question[gq.PeopleRelationship] = getOptionsObj("Father,Mother,Brother,Sister,Son,Daughter,Spouse,Friend,Grandfather Paternal,Grandmother Paternal,Grandfather Maternal,Grandmother Maternal", ",", true, Global.NoneOfTheAbove);
  question[gq.CareerIndustry] = getOptionsObj("Advertising and Marketing;Aerospace;Agriculture;Arts;Business;Charity;Computer and Technology;Construction;Defense;Education;Energy;Engineering;Entertainment;Environment;Fashion;Economics and Finance;Food and Beverage;Government;Health Care;Hospitality;Law;Manufacturing;Media and News;Mining;Pharmaceutical;Politics;Retail;Science;Sports;Talent;Telecommunication;Transportation", ";", true, Global.NoneOfTheAbove);
  question[gq.HealthIssues] = "";

  question[gq.Gender] = {}; // getOptionsObj("Biological Male;Biological Female", ";", true);
  question[gq.Gender][Global.BiologicalMale] = false;
  question[gq.Gender][Global.BiologicalFemale] = false;

  const personType = "leader;people person;fighter;smart;witty;wise;artistic;creative;hardworking;cunning;deceptive;shy;solitary;hermit";
  question[gq.RomanticPartnerType] = getOptionsObj(personType, ";",false,Global.NoneOfTheAbove);
  question[gq.MotherType] = getOptionsObj(personType, ";",false,Global.NoneOfTheAbove);
  return question;
}

export { Global, defaultQuestion, getBlankDateArray, ccDefaultQuestion };
