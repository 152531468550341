import React from "react";

// We use Route in order to define the different routes of our application
import { Route } from "react-router-dom";

/**
 * By Dark racer
 */
import CssBaseline from '@mui/material/CssBaseline';

// We import all the components we need in our app
// import NavBar from "./components/v2/navbar";
import { NavLink } from "react-router-dom";


// import SubcatPicker from "./components/subcatpicker";
// import Questions from "./components/questions";
// import Checkout from "./components/checkout";
// import Faq from "./components/faq";
// import Download from "./components/download";
// import FindReport from "./components/findreport";
// import PaymentFailure from "./components/paymentfailure";
// import TermsAndConditions from "./components/termsconditions";
// import Privacy from "./components/privacy";
// import Affiliate from "./components/affiliate";
import axios from "axios";
import { Global, defaultQuestion } from "./global";
import './fonts/Montserrat-VariableFont_wght.ttf'
import "./index.css";

// ref: https://github.com/fingerprintjs/fingerprintjs-react-example
import FingerprintJS from "@fingerprintjs/fingerprintjs";

import { Box, Container } from "@mui/material";
// import AboutUs from "./components/v2/AboutUs";
import { themeOptions } from './Theme';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import store from './store'
import { Provider, useDispatch, useSelector } from "react-redux";
import { getCcQuestionData, getQuestionData, getQuestionNAData, isDate, validateDateRange } from "./redux/Helper";
import { useState } from "react";
import { useEffect } from "react";
// import Footer from "./components/v2/Footer";
import { saveAllQuestions, saveAllReportsData, saveDynamicQuestions, saveMaintenanceData, saveMetricsData, saveNAQuestions, savePurchaseReportsData, saveTrialReportsData, updatePurchaseReportsData } from "./redux/actions/appActions";
import { Suspense } from "react";
import { reduce } from "lodash";
import { List, Map } from "immutable";
import HomePage from "./components/v2/HomePage";
import ComputeBar from "./components/v2/ComputeBar";
import AlertBox from "./components/v2/AlertBox";
import Maintenance from "./components/v2/Maintenance";
import CoupleCompatibility from "./components/CoupleCompatibility";
import { isEmpty } from "lodash";
import { clone } from "lodash";
import { omit } from "lodash";
import { keys } from "lodash";
import { pickBy } from "lodash";
import { identity } from "lodash";
import { startsWith } from "lodash";
const NavBar = React.lazy(() => import("./components/v2/navbar"));
const SubcatPicker = React.lazy(() => import("./components/subcatpicker"));
const Questions = React.lazy(() => import("./components/questions"));
const Checkout = React.lazy(() => import("./components/checkout"));
const Faq = React.lazy(() => import("./components/faq"));
const Download = React.lazy(() => import("./components/download"));
const FindReport = React.lazy(() => import("./components/findreport"));
const PaymentFailure = React.lazy(() => import("./components/paymentfailure"));
const TermsAndConditions = React.lazy(() => import("./components/termsconditions"));
const Privacy = React.lazy(() => import("./components/privacy"));
const Affiliate = React.lazy(() => import("./components/affiliate"));
const AboutUs = React.lazy(() => import("./components/v2/AboutUs"));
const Footer = React.lazy(() => import("./components/v2/Footer"));

const DEV_couple_compatibility = React.lazy(() => import("./components/couple_compatibility_dev"));

// import { LocalizationProvider } from "@mui/x-date-pickers";

const GLOBAL_QUESTIONS = Global.question;

const metricTicks = 5; // seconds

const AppV2 = (props) => {

  const theme = createTheme(themeOptions);
  const dispatch = useDispatch();
  const purchaseReports = useSelector(state => state.appReducer.purchaseReports)
  const affiliatorCode = useSelector(state => state.appReducer.affiliatorCode);
  const trialSelected = useSelector(state => state.appReducer.trialSelected);

  const [trial, setTrial] = useState([]);
  const [showAlert, setShowAlert] = useState(false);
  const [purchase, setPurchase] = useState([]);
  const [freeTrialEligible, setFreeTrialEligible] = useState(false);
  const [purchaseSelected, setPurchaseSelected] = useState({});
  const [questionData, setQuestionData] = useState({});
  const [ccQuestionData, setCcQuestionData] = useState({});
  const [questionNotApplicable, setQuestionNotApplicable] = useState({});
  const [hasValidData, setHasValidData] = useState(false);
  const [validationMessages, setValidationMessages] = useState([]);
  const agreeAccuracy = useSelector(state => state.appReducer.agreeement);
  const [showOptionalQuestions, setShowOptionalQuestions] = useState(false);
  const [helloWorld, setHelloWorld] = useState(Math.random().toString());
  const [typeOfQuiz, setTypeOfQuiz] = useState('');
  const [isCCTrail, setIsCCTrail] = useState(false);
  const [isSrReport, setIsSrReport] = useState(false);
  const [isCCReport, setIsCCReport] = useState(false);

  const showSummaryStore = useSelector(state => state.appReducer.showSummary);
  const computeMetrics = () => {
    const currentRoute = props.history.location.pathname;
    const metrics = JSON.parse(localStorage.getItem('metrics'))
    if (!(currentRoute in metrics)) {
      metrics[currentRoute] = 0;
    }
    metrics[currentRoute] += metricTicks;
    localStorage.setItem('metrics', JSON.stringify(metrics))
  }

  const validateQuestionData = () => {
    console.log("Affiliator code ---", affiliatorCode);
    let hasValidData = true;
    let validationMessages = [];
    if (isEmpty(questionData)) return;
    if (!isDate(questionData.dob)) {
      validationMessages.push("Fill in your date of birth");
    } else {
      if (isDate(questionData.Mdb) &&
        questionData.Mdb > questionData.dob) {
        validationMessages.push(`Invalid mother's date of birth ${questionData.Mdb.toLocaleDateString('en-us', { day:"numeric", month:"short", year:"numeric"})}. Must be before your date of birth ${questionData.dob.toLocaleDateString('en-us', { day:"numeric", month:"short", year:"numeric"})}`)
      }
      if (isDate(questionData.Fdb) &&
        questionData.Fdb > questionData.dob) {
        validationMessages.push(`Invalid father's date of birth ${questionData.Fdb.toLocaleDateString('en-us', { day:"numeric", month:"short", year:"numeric"})}. Must be before your date of birth ${questionData.dob.toLocaleDateString('en-us', { day:"numeric", month:"short", year:"numeric"})}`)
      }
      questionData.Sodb.forEach(data => {
        if (isDate(data) &&
          questionData.dob > data) {
          validationMessages.push(`Invalid son's date of birth ${data.toLocaleDateString('en-us', { day:"numeric", month:"short", year:"numeric"})}. Must be after your date of birth ${questionData.dob.toLocaleDateString('en-us', { day:"numeric", month:"short", year:"numeric"})}`)
        }
      })
      questionData.Ddb.forEach(data => {
        if (isDate(data) &&
          questionData.dob > data) {
          validationMessages.push(`Invalid daughter's date of birth ${data.toLocaleDateString('en-us', { day:"numeric", month:"short", year:"numeric"})}. Must be after your date of birth ${questionData.dob.toLocaleDateString('en-us', { day:"numeric", month:"short", year:"numeric"})}`)
        }
      })
    }
    if (
      !questionData.geo ||
      (questionData.geo[0] === 0 &&
        questionData.geo[1] === 0)
    ) {
      validationMessages.push("Fill in your place of birth");
    }

    const rangeProps = [
      GLOBAL_QUESTIONS.PLgr,
      GLOBAL_QUESTIONS.PLbr,
      GLOBAL_QUESTIONS.Wbv,
      GLOBAL_QUESTIONS.Wbh,
      GLOBAL_QUESTIONS.Rl,
      GLOBAL_QUESTIONS.Hp,
      GLOBAL_QUESTIONS.Hm,
      GLOBAL_QUESTIONS.Cs,
      GLOBAL_QUESTIONS.Csb,
      GLOBAL_QUESTIONS.Wn,
      GLOBAL_QUESTIONS.Wo,
      GLOBAL_QUESTIONS.Eds,
      GLOBAL_QUESTIONS.Ede,
    ];
    let filledDts = 0;
    rangeProps.forEach((prop, i) => {
      questionData[prop].forEach((data, j) => {
        if (data) {
          const start = data[0];
          const end = data[1];
          const desc = Global.questionDescription[prop];
          validateDateRange(start, end, validationMessages, desc);
          filledDts += 1;
        }
      });
    });

    const dateProps = [
      GLOBAL_QUESTIONS.Mdb,
      GLOBAL_QUESTIONS.Fdb,
      GLOBAL_QUESTIONS.Brdb,
      GLOBAL_QUESTIONS.Srdb,
      GLOBAL_QUESTIONS.Sodb,
      GLOBAL_QUESTIONS.Ddb,
      GLOBAL_QUESTIONS.Spdb,
      GLOBAL_QUESTIONS.Frdb,
    ];
    dateProps.forEach((prop) => {
      const value = questionData[prop];
      var list = value instanceof Array ? value : [value];
      const desc = Global.questionDescription[prop];
      list.forEach((item) => {
        if (isDate(item)) {
          filledDts += 1;
          const now = new Date();
          if (item > now) {
            validationMessages.push(
              `${desc} invalid : date ${item.toLocaleDateString()} cannot be in future`
            );
          }
        }
      });
    });

    const gender = questionData[GLOBAL_QUESTIONS.Gender];
    if (gender[Global.BiologicalMale] === false &&
      gender[Global.BiologicalFemale] === false) {
      validationMessages.push("Select your biological gender");
    }

    if (filledDts < 10) {
      validationMessages.push("Enter at least 10 calendar event dates");
    }

    let answeredCheckBoxes = 0;
    Object.entries(questionData).forEach((prop) => {
      const key = prop[0];
      const value = prop[1];
      if (rangeProps.indexOf(key) === -1 && dateProps.indexOf(key) === -1) {
        if (value && typeof value == "object") {
          const checkedAnswer = Object.entries(value).filter((elem) => {
            return elem[1] === true;
          });
          const checkedAnswerCount = checkedAnswer.length;
          if (checkedAnswerCount > 0) {
            answeredCheckBoxes += 1;
          }
        }
      }
    });
    if (answeredCheckBoxes < 8) {
      validationMessages.push(
        "Answer all 8 multiple choice questions"
      );
    }

    if (validationMessages.length > 0) {
      hasValidData = false;
    }
    /*** ADDED 2024.02.10 ***/
    if (affiliatorCode && affiliatorCode.startsWith("INT")) {
      hasValidData = true;
      validationMessages = [];
    }
    /*** END 2024.02.10 ***/
    validationMessages = validationMessages.filter((x, i) => validationMessages.indexOf(x) === i)
    setHasValidData(hasValidData);
    setValidationMessages(validationMessages);
    checkForReportType(hasValidData)
  }

  const handlePurchaseSelected = (catagoryCode, isSelected, cat = 'all_category') => {
    // if (isSelected === true) {
    //   if (catagoryCode === "Hp") {
    //     setPurchaseSelected({ ...purchaseSelected, 'Hpwq': false });
    //   } else if (catagoryCode === "Hpwq") {
    //     setPurchaseSelected({ ...purchaseSelected, 'Hp': false });
    //   }
    // }
    const option = {
      catagoryName: cat,
      subCatagoryName: catagoryCode,
      subCatagoryValue: isSelected
    }
    //dispatch(updatePurchaseReportsData(option))
    if (catagoryCode === 'Hpwq') {
      setPurchaseSelected({ ...purchaseSelected, 'Hp': false, [catagoryCode]: isSelected })
    } else if (catagoryCode === 'Hp') {
      setPurchaseSelected({ ...purchaseSelected, 'Hpwq': false, [catagoryCode]: isSelected })
    } else if (catagoryCode === 'rnms') {
      setPurchaseSelected({ ...purchaseSelected, 'rnmswq': false, [catagoryCode]: isSelected })
    } else if (catagoryCode === 'rnmswq') {
      setPurchaseSelected({ ...purchaseSelected, 'rnms': false, [catagoryCode]: isSelected })
    }else {
      setPurchaseSelected({ ...purchaseSelected, [catagoryCode]: isSelected })
    }
  }

  useEffect(() => {
    checkForReportType(hasValidData)
  }, [purchaseSelected])

  const checkForReportType = (isValid) => {
    const hasValidData = hasValidData || isValid;
    if(!isEmpty(purchaseSelected)) {
      const purchaseCopy = clone(purchaseSelected)
      const standardReport = !isEmpty(keys(pickBy(omit(purchaseCopy, 'cc'), identity)));
      const ccReport = !!purchaseSelected['cc'];
      if(!hasValidData && standardReport) {
        setTypeOfQuiz('sr')
      } else if (!showSummaryStore && ccReport) {
        setTypeOfQuiz('cc')
      }   else {
        setTypeOfQuiz('')
      }
      setIsCCReport(ccReport);
      setIsSrReport(standardReport);
    } else {
      if(!affiliatorCode) {
        setTypeOfQuiz('');
      } else if(!hasValidData && !isCCTrail) {
        setTypeOfQuiz('sr')
      } else if (!showSummaryStore &&  isCCTrail) {
        setTypeOfQuiz('cc')
      } else {
        setTypeOfQuiz('');
      }
    }

  }

  useEffect(()=> {
    setTypeOfQuiz(false);
    setIsCCReport(false);
    if(affiliatorCode) {
      if(startsWith(affiliatorCode, 'CC')) {
        setIsCCTrail(true);
        setIsCCReport(true);
      } else {
        setIsCCTrail(false);
        setIsSrReport(true);
      }
    }
  }, [affiliatorCode])

  const handlePropUpdate = (fn) => {
    let newState = Object.assign({}, questionData);
    fn(newState);
    setQuestionData(newState);
  }

  const handleQuestionNAUpdate = (prop, value) => {
    let newState = Object.assign({}, questionNotApplicable);
    newState[prop] = value
    if (value === true) {
      const questions = defaultQuestion();
      const defination = questions[prop];
      handlePropUpdate((qd) => {
        qd[prop] = defination;
      });
    }
    setQuestionNotApplicable(newState);
  }

  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_SERVER}/buddy/maintenance`)
      .then(({ data }) => {
        if(data && data[0] && data[0].status) {
          props.history.push('/maintenance')
          dispatch(saveMaintenanceData(data[0]));
          return;
        } else {
          setShowAlert(true);
        }
      })
      .catch(function (error) {
        console.log(error);
      });


    axios
      .get(`${process.env.REACT_APP_SERVER}/subcategory/list`)
      .then(({ data }) => {
        let purchaseSelected = {};
        data.purchase.forEach((c) => {
          c.subcategories.forEach((x) => {
            purchaseSelected[x.code] = false;
            x.selected = false;
          });
        });
        const { trial, purchase } = data;
        setTrial(trial);
        setPurchase(purchase)
        dispatch(saveTrialReportsData(trial));
        dispatch(savePurchaseReportsData(purchase));
      })
      .catch(function (error) {
        console.log(error);
      });

    FingerprintJS.load()
      .then((fp) => fp.get())
      .then((result) => {
        setHelloWorld(result.visitorId)
        axios
          .get(`${process.env.REACT_APP_SERVER}/freetrialeligible`, {
            params: {
              helloWorld: result.visitorId,
            },
          })
          .then((response) => {
            setFreeTrialEligible(response.data.is_freetrial_eligible);
          })
          .catch(function (error) {
            console.log("free trial error", error);
          });
      });

    setQuestionData(getQuestionData());
    setQuestionNotApplicable(getQuestionNAData());
    setCcQuestionData(getCcQuestionData());
    //dispatch(saveAllQuestions('showSummary', JSON.parse(localStorage.getItem('showSummary'))));
    // dispatch(saveDynamicQuestions(JSON.parse(localStorage.getItem('quizdata'))))
    // dispatch(saveDynamicQuestions(JSON.parse(localStorage.getItem('person2_quiz_data'))))
    localStorage.setItem('metrics', JSON.stringify({
      "/": 0,
      "/quiz": 0,
      "/cart": 0,
      "/cc": 0,
    }))
    setInterval(() => {
      computeMetrics();
    }, metricTicks * 1000);

  }, []);

  useEffect(() => {
    if (List.isList(purchaseReports)) {
      setPurchase(purchaseReports.toJS())

      const purchasedReport = reduce(purchaseReports.map(p => p.get('subcategories')).toJS().flat(), function (result, value, key) {
        if (value.selected)
          result[value.code] = value.selected
        return result;
      }, {});
      setPurchaseSelected(purchasedReport)
    }

  }, [purchaseReports])

  useEffect(() => {
    if (questionData) {
      Object.entries(questionData).forEach(question => dispatch(saveAllQuestions(question[0], question[1])));
      //validateQuestionData()
    }

  }, [questionData])

  useEffect(() => {
    if (ccQuestionData)
      Object.entries(ccQuestionData).forEach(question => {
        if(['person1_quiz_data', 'person2_quiz_data'].includes(question[0])) {
          dispatch(saveAllQuestions(question[0], []))
          dispatch(saveDynamicQuestions(question[0], question[1]))
        } else {
          dispatch(saveAllQuestions(question[0], question[1]))
        }
      });
  }, [ccQuestionData])

  useEffect(() => {
    Object.entries(questionNotApplicable).forEach(question => dispatch(saveNAQuestions(question[0], question[1])));
    // dispatch(saveNAQuestions(question[0], question[1]))
  }, [questionNotApplicable])

  const refreshQuestionData = () => {
    setQuestionData(getQuestionData());
    setQuestionNotApplicable(getQuestionNAData());
    // setTimeout(() => validateQuestionData(), 1000)
  }

  const refreshCcQuestionData = () => {
    setCcQuestionData(getCcQuestionData());
  }

  return (
    <Box id="appdiv" sx={{
      background: '#f4f4f4',
      height: '100vh',
      overflowY: 'auto',
      position: 'relative',
      zIndex: 1
    }}>
      <Provider store={store}>
        <ThemeProvider theme={theme}>
          <CssBaseline />
          {window.location.pathname !== '/maintenance' ?
            <>
              {showAlert ? <AlertBox />: null}
              <NavBar history={props.history} purchaseSelected={purchaseSelected} />
            </>
            : null}
          {window.location.pathname === '/quiz' && agreeAccuracy ? <Box className='quizprogressdesktop'>
              <ComputeBar />
            </Box> : null}
          {window.location.pathname !== '/' ?
            <>
              <Route exact path="/maintenance">
                <Maintenance
                  history={props.history}
                />
              </Route>
              {window.location.pathname !== '/maintenance' ?
              <>
                <Container maxWidth="lg" fixed elevation={3} className="root-container">
                  <div
                    // style={{ paddingLeft: "10px", paddingRight: "10px" }}
                  >
                    <Box id="top"  className="top-cantainer">

                    </Box>
                    <Route exact path="/insights">
                      <SubcatPicker
                        purchaseSelected={purchaseSelected}
                        history={props.history}
                        purchaseChanged={handlePurchaseSelected}
                        isCCReport={isCCReport && !isSrReport}
                      />
                    </Route>
                    <Route exact path="/quiz">
                      <Questions
                        checksum={helloWorld}
                        history={props.history}
                        questionData={questionData}
                        questionNotApplicable={questionNotApplicable}
                        refreshQuestionData={refreshQuestionData}
                        purchaseSelected={purchaseSelected}
                        isCCReport={isCCReport}
                        isBothReportSelected={isCCReport && isSrReport}
                      />
                    </Route>
                    <Route exact path="/cc">
                      <CoupleCompatibility
                        checksum={helloWorld}
                        history={props.history}
                        questionData={ccQuestionData}
                        showSummaryStore={showSummaryStore}
                        affiliatorCode={affiliatorCode}
                        isBothReportSelected={isCCReport && isSrReport}
                        purchaseChanged={handlePurchaseSelected}
                        refreshQuestionData={refreshCcQuestionData}
                      />
                    </Route>
                    <Route exact path="/cart">
                      <Checkout
                        trialSelected={trialSelected}
                        trial={trial}
                        purchase={purchase}
                        purchaseSelected={purchaseSelected}
                        hasValidData={hasValidData}
                        validationMessages={validationMessages}
                        affiliatorCode={affiliatorCode}
                        isCCTrail={isCCTrail}
                        helloWorld={helloWorld}
                        questionData={questionData}
                        ccQuestionData={ccQuestionData}
                        questionNotApplicable={questionNotApplicable}
                        history={props.history}
                        showSummaryStore={showSummaryStore}
                        typeOfQuiz={typeOfQuiz}
                        isCCReport={isCCReport}
                        isSrReport={isSrReport}
                        purchaseChanged={handlePurchaseSelected}
                        onvalidate={() => validateQuestionData()}
                      />
                    </Route>
                    <Route exact path="/faq">
                      <Faq />
                    </Route>
                    <Route exact path="/download/:sessionId" component={Download} />
                    <Route exact path="/findreport">
                      <FindReport history={props.history} />
                    </Route>
                    <Route exact path="/paymentfailure">
                      <PaymentFailure />
                    </Route>
                    <Route
                      exact
                      path="/termsandconditions"
                    >
                      <TermsAndConditions history={props.history} />
                    </Route>
                    <Route exact path="/privacy"  >
                      <Privacy history={props.history} />
                    </Route>
                    <Route exact path="/affiliate">
                      <Affiliate />
                    </Route>
                    <Route exact path="/about">
                      <AboutUs />
                    </Route>
                    <Route exact path="/devcc">
                      <DEV_couple_compatibility />
                    </Route>
                  </div>
                </Container>
                <div style={{ clear: "both", height: "70px" }} />
                <div id="footer"><Footer /></div>
              </> : null }
            </>
            : <Route exact path="/">
              <HomePage
                history={props.history}
              />
            </Route>}
        </ThemeProvider>
      </Provider>
    </Box>
  );
}

export default AppV2;
