import * as React from 'react';
import { styled } from '@mui/material/styles';
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';
import MuiAccordion, { AccordionProps } from '@mui/material/Accordion';
import MuiAccordionSummary, {
    AccordionSummaryProps,
} from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import { AccordionActions, Avatar, Box, Button, IconButton } from '@mui/material';
import { Link } from 'react-router-dom';

const Accordion = styled((props) => (
    <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
    border: `1px solid ${theme.palette.divider}`,
    '&:not(:last-child)': {
        borderBottom: 0,
    },
    '&:before': {
        display: 'none',
    },
}));

const AccordionSummary = styled((props) => (
    <MuiAccordionSummary
        // expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: '0.9rem' }} />}
        expandIcon={<IconButton color="primary" component="label">
            <Avatar
                alt="Green"
                src={`landing/${props.secId}.png`}
                sx={{ width: 24, height: 24 }}
            />
        </IconButton>}
        {...props}
    />
))(({ theme }) => ({
    backgroundColor:
        theme.palette.mode === 'dark'
            ? 'rgba(255, 255, 255, .05)'
            : 'rgba(0, 0, 0, .03)',
    flexDirection: 'row-reverse',
    '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
        transform: 'rotate(90deg)',
    },
    '& .MuiAccordionSummary-content': {
        marginLeft: theme.spacing(1),
    },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
    padding: theme.spacing(2),
    borderTop: '1px solid rgba(0, 0, 0, .125)',
}));

export default function CollapsibleSection({ handlePanelKey, catagory, secId, explandLast }) {
    const [expanded, setExpanded] = React.useState(explandLast ? explandLast : 'panel1');

    const handleChange =
        (panel) => (event, newExpanded) => {
            setExpanded(newExpanded ? panel : false);
            handlePanelKey(panel);
        };

    return (
        <Box className="section">
            <Typography variant='subtitle1' className='heading'>{catagory.heading}</Typography>
            <Typography variant='body1' className='sub-heading' sx={{
                margin: '16px 0 !important',
                textAlign: 'left !important'
            }}>{catagory.subHeading}</Typography>
            {
                catagory.list.map((item, index) => {
                    return (
                        <Accordion expanded={expanded === item.key} onChange={(e) => handleChange(item.key)(e, expanded)} key={item.key} sx={{
                            border: 'none',
                            width: '350px',
                        }}>
                            <AccordionSummary sx={{
                                background: 'transparent',
                                m: 0,
                                p: 0
                            }} secId={secId}>
                                <Typography variant="subtitle1" className='heading-title' sx={{
                                    textDecoration: expanded === item.key ? 'underline' : 'none',
                                    p: '0 !important'
                                }}>{item.title}</Typography>
                            </AccordionSummary>
                            <AccordionDetails sx={{
                                ml: 3,
                                border: 'none',
                                p: 0
                            }}>
                                <ul style={{ margin: '0 auto 18px' }}>
                                    {
                                        item.subList.map((subItem, index) => {
                                            return (
                                                <li key={index}>
                                                    <Typography variant='subtitle1' className='heading-title' sx={{
                                                        // maxWidth: 200,
                                                        textDecoration: 'none',
                                                    }}>
                                                        {subItem}
                                                    </Typography>
                                                </li>
                                            )
                                        })
                                    }
                                </ul>

                            </AccordionDetails>
                        </Accordion>
                    )
                })
            }
            <Link to={{
                pathname: "/insights",
                hash: "#top",
            }} style={{
                display: 'flex',
                marginTop: '90px',
                justifyContent: 'center',
                textDecoration: 'none'
            }}>
                <Button variant="contained" sx={{
                    background: '#b99a38',
                    color: '#fff'
                }}>
                    Act Now
                </Button>
            </Link>
        </Box>
    );
}
