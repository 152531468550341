import { Box, Button, Grid, IconButton, Typography } from "@mui/material";

import React from "react";
import { withRouter } from "react-router-dom";
import { isMobile } from "react-device-detect";
import BuddyNowImg from "./assets/BuddyNowImg";
import { useState } from "react";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import Moment from 'moment'
import { Map } from "immutable";

const Maintenance = ({ history }) => {
    const trialReports = useSelector(state => state.appReducer.maintenance);
    const [data, setData] = useState();
    let timmerRef = null;

    useEffect(()=>{
        const b=trialReports;
        setData(trialReports)
        const a = Moment;
        if (Map.isMap(trialReports)) {
            setData(Moment.duration(Moment(trialReports.get('ends_at')).diff(Moment())))
            timmer();
        }
    }, [trialReports])

    const timmer = () => {
        timmerRef = setInterval(function() {
            setData(Moment.duration(Moment(trialReports.get('ends_at')).diff(Moment())))
        }, 1000)
    }

    useEffect(()=>{
        return ()=> clearInterval(timmerRef)
    }, [])


    return (
        <Box className="maintenance" sx={{
            background: '#ede6cd',
            minHeight: 'calc(100vh)', overflow: 'hidden',
        }}>
            <Grid container spacing={2} >
                <Grid item xs={12}>
                    <Box display={'flex'} alignContent='center' justifyContent={'center'} sx={{
                        margin: '40px auto'
                    }} className="no-m--sm">
                        <IconButton>
                            <Box
                                component="img"
                                sx={{
                                    padding: 1,
                                }}
                                alt="Buddy Now logo"
                                src={'/buddynow.svg'}
                            >
                            </Box>
                        </IconButton>
                    </Box>
                </Grid>
                <Grid item xs={12}>
                    <Box display={'flex'} alignContent='center' justifyContent={'center'}  sx={{
                        margin: '40px auto'
                    }}  className="no-m--sm">
                        <Typography variant="h5" sx={{
                            fontSize: '27px',
                            lineHeight: '33px'
                        }} className="header">
                            Scheduled Maintenance
                        </Typography>
                    </Box>
                </Grid>
                <Grid item xs={12} md={3} sx={{
                        margin: '40px auto'
                    }}  className="no-m--sm">
                    <Box display={'flex'} alignContent='center' justifyContent={'center'} sx={{ mt: 1,textAlign: 'center' }}>
                        <Typography variant="body1" sx={{
                            fontWeight: 600,
                            fontSize: '20px',
                            lineHeight: '24px'
                        }} className="subHeader">
                            Your current time is
                        </Typography>
                    </Box>
                    <Box display={'flex'} alignContent='center' justifyContent={'center'} sx={{ mt: 1 }}>
                        <Typography variant="body2" sx={{
                            fontWeight: 300,
                            fontSize: '20px',
                            lineHeight: '24px',
                            my: 1
                        }} className="subDesc">
                            {Moment().format('LTS')}
                        </Typography>
                    </Box>
                    <Box display={'flex'} alignContent='center' justifyContent={'center'} sx={{ mt: 1 }}>
                        <Typography variant="body2" sx={{
                            fontWeight: 300,
                            fontSize: '20px',
                            lineHeight: '24px',
                            my: 1
                        }} className="subDesc">
                            {Moment().format('ll')}
                        </Typography>
                    </Box>
                </Grid>
                <Grid item xs={12} md={6} sx={{
                        margin: '40px auto'
                    }} className="no-m--sm">
                    <Grid container>
                        <Grid item xs={12}>
                            <Box display={'flex'} alignContent='center' justifyContent={'center'} sx={{ mt: 1 }}>
                                <Typography variant="body1" sx={{
                            fontWeight: 600,
                            fontSize: '20px',
                            lineHeight: '24px'
                        }}>
                                    Countdown
                                </Typography>
                            </Box>
                        </Grid>
                        <Grid item xs={12} display='flex' alignContent={'center'} justifyContent='space-between' sx={{
                            m: 1
                        }}>
                            <Box display={'flex'} alignContent='center' justifyContent={'center'} sx={{ mt: 1 }}>
                                <Typography variant="body1" sx={{
                                    fontWeight: 400,
                                    fontSize: '50px',
                                    lineHeight: '61px',
                                    color: '#50960B'
                                }} className="clock">
                                    {data ? data.days()  : '0'} day
                                </Typography>
                            </Box>
                            <Box display={'flex'} alignContent='center' justifyContent={'center'} sx={{ mt: 1 }}>
                                <Typography variant="body1" sx={{
                                    fontWeight: 400,
                                    fontSize: '50px',
                                    lineHeight: '61px',
                                    color: '#50960B'
                                }} className="clock">
                                    {data ? data.hours() : '0'} hr
                                </Typography>
                            </Box>
                            <Box display={'flex'} alignContent='center' justifyContent={'center'} sx={{ mt: 1 }}>
                                <Typography variant="body1" sx={{
                                    fontWeight: 400,
                                    fontSize: '50px',
                                    lineHeight: '61px',
                                    color: '#50960B'
                                }} className="clock">
                                    {data ? data.minutes() : '0'} min
                                </Typography>
                            </Box>
                            <Box display={'flex'} alignContent='center' justifyContent={'center'} sx={{ mt: 1 }}>
                                <Typography variant="body1" sx={{
                                    fontWeight: 400,
                                    fontSize: '50px',
                                    lineHeight: '61px',
                                    color: '#50960B'
                                }} className="clock">
                                    {data ? data.seconds() : '0'} sec
                                </Typography>
                            </Box>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12} md={3} sx={{
                        margin: '40px auto'
                    }} className="no-m--sm">
                    <Box display={'flex'} alignContent='center' justifyContent={'center'} sx={{ mt: 1, textAlign: 'center' }}>
                        <Typography variant="body1"  sx={{
                            fontWeight: 600,
                            fontSize: '20px',
                            lineHeight: '24px'
                        }} className="subHeader">
                            BuddyNow will be back up at
                        </Typography>
                    </Box>
                    <Box display={'flex'} alignContent='center' justifyContent={'center'} sx={{ mt: 1 }}>
                        <Typography variant="body2" sx={{
                            fontWeight: 300,
                            fontSize: '20px',
                            lineHeight: '24px',
                            my: 1
                        }} className="subDesc">
                            {trialReports ? Moment(trialReports.get('ends_at')).format('LTS') : '-'}
                        </Typography>
                    </Box>
                    <Box display={'flex'} alignContent='center' justifyContent={'center'} sx={{ mt: 1 }}>
                        <Typography variant="body2" sx={{
                            fontWeight: 300,
                            fontSize: '20px',
                            lineHeight: '24px',
                            my: 1
                        }} className="subDesc">
                            {trialReports ? Moment(trialReports.get('ends_at')).format('ll') : '-'}
                        </Typography>
                    </Box>
                </Grid>
                <Grid item xs={12}>
                    <Box display={'flex'} alignContent='center' justifyContent={'center'}  sx={{
                        margin: '10px auto'
                    }}>
                        <Typography variant="h5" sx={{
                            fontWeight: 300,
                            fontSize: '20px',
                            lineHeight: '24px',
                            m: 1,
                            textAlign: 'center'
                        }}>
                            While you are waiting for us to get back up, check out our 
                        </Typography>
                    </Box>
                </Grid>
                <Grid item xs={12}>
                    <Box display={'flex'} alignContent='center' justifyContent={'center'}  sx={{
                        margin: '10px auto'
                    }}>
                        <a href="https://www.reddit.com/r/BuddyNow/comments/uk7s12/just_curious_are_your_reports_accurate/" target={'_blank'} style={{
                            fontWeight: 400,
                            fontSize: '27px',
                            lineHeight: '33px'
                        }} className="customer-testimonials">
                        Customers’ testimonials
                        </a>
                       
                    </Box>
                </Grid>
            </Grid>
        </Box>
    );
}

export default withRouter(Maintenance);
