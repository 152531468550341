import React from "react";
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import { useSelector } from "react-redux";

const ComputeBar = () => {
    const progressPercent = useSelector(state => state.appReducer.computeValue);

    return (
        <CircularProgressbar
            value={progressPercent}
            text={`${progressPercent}%`}
            styles={buildStyles({
                textColor: "#b99a38",
                pathColor: "#b99a38",
                backgroundColor: "white",
            })}
            background="true"
        />
    );
}

export default ComputeBar;
