import * as React from 'react';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import Divider from '@mui/material/Divider';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import Avatar from '@mui/material/Avatar';
import Typography from '@mui/material/Typography';
import Moment from 'moment';
import { useSelector } from 'react-redux';
import { AppBar, Box, Toolbar } from '@mui/material';
import { useEffect } from 'react';

export default function ShowSummary({ questionData }) {
    const person1QuizData = useSelector(state => state.appReducer.person1_quiz_data);
    const person2QuizData = useSelector(state => state.appReducer.person2_quiz_data);
    useEffect(() => {
        document.title = "BuddyNow - Couple Compatibility";
        document.querySelector('#top').scrollIntoView({
            behavior: 'smooth'
          });
      }, []);
    return (
        <Box id='top'>
            <Box sx={{ flexGrow: 1 }}>
                <AppBar position="static" sx={{
                    marginTop: '16px',
                    borderRadius: '4px'
                }}>
                    <Toolbar>
                        <Typography variant="h6" component="div" sx={{
                            flexGrow: 1,
                            textAlign: 'center',
                            fontSize: '23px',
                            fontWeight: 'bold',
                            color: 'white'
                        }}>
                            Your Quiz Inputs
                        </Typography>
                    </Toolbar>
                </AppBar>
            </Box>
            <Box>
                <List sx={{
                    width: '100%', bgcolor: 'background.paper', border: '2px solid red',
                    padding: '8px',
                    margin: '16px 0',
                    borderRadius: '8px'
                }}>
                    <Box>
                        <Typography variant="body1" component="div" sx={{
                            flexGrow: 1, textAlign: 'center',
                            fontSize: '20px',
                            fontWeight: 'bold'
                        }}>
                            This set of questions are for You
                        </Typography>
                    </Box>
                    <ListItem alignItems="flex-start">
                        <ListItemText
                            primary={<Typography
                                sx={{ display: 'inline' }}
                                component="span"
                                variant="body1"
                                gutterBottom
                                color="text.primary"
                            >
                                Name to identify yourself?
                            </Typography>}
                            secondary={
                                <React.Fragment>
                                    <Typography
                                        sx={{ display: 'inline', pt: 1 }}
                                        component="span"
                                        variant="caption"
                                        color="text.primary"
                                    >
                                        Ans:&nbsp;
                                    </Typography>
                                    {questionData['person1_name']}
                                </React.Fragment>
                            }
                        />
                    </ListItem>
                    <Divider variant="inset" component="li" sx={{
                        margin: '0',
                        padding: '0 !important'
                    }} />
                    <ListItem alignItems="flex-start">
                        <ListItemText
                            primary={<Typography
                                sx={{ display: 'inline' }}
                                component="span"
                                variant="body1"
                                gutterBottom
                                color="text.primary"
                            >
                                Where were you born?
                            </Typography>}
                            secondary={
                                <React.Fragment>
                                    <Typography
                                        sx={{ display: 'inline' }}
                                        component="span"
                                        variant="body2"
                                        color="text.primary"
                                    >
                                        Ans:&nbsp;
                                    </Typography>
                                    {questionData['person1_pob']}
                                </React.Fragment>
                            }
                        />
                    </ListItem>
                    <Divider variant="inset" component="li" sx={{
                        margin: '0',
                        padding: '0 !important'
                    }} />
                    <ListItem alignItems="flex-start">
                        <ListItemText
                            primary={<Typography
                                sx={{ display: 'inline' }}
                                component="span"
                                variant="body1"
                                gutterBottom
                                color="text.primary"
                            >
                                What is your date of birth?
                            </Typography>}
                            secondary={
                                <React.Fragment>
                                    <Typography
                                        sx={{ display: 'inline' }}
                                        component="span"
                                        variant="body2"
                                        color="text.primary"
                                    >
                                        Ans:&nbsp;
                                    </Typography>
                                    {Moment(questionData['person1_dob']).format('DD-MMM-YYYY')}
                                </React.Fragment>
                            }
                        />
                    </ListItem>
                    <Divider variant="inset" component="li" sx={{
                        margin: '0',
                        padding: '0 !important'
                    }} />
                    <ListItem alignItems="flex-start">
                        <ListItemText
                            primary={<Typography
                                sx={{ display: 'inline' }}
                                component="span"
                                variant="body1"
                                gutterBottom
                                color="text.primary"
                            >
                                What is your biological gender?
                            </Typography>}
                            secondary={
                                <React.Fragment>
                                    <Typography
                                        sx={{ display: 'inline' }}
                                        component="span"
                                        variant="body2"
                                        color="text.primary"
                                    >
                                        Ans:&nbsp;
                                    </Typography>
                                    {questionData['person1_gender'] === 'M' ? 'Male' : 'Female'}
                                </React.Fragment>
                            }
                        />
                    </ListItem>
                    {
                        person1QuizData ? (
                            person1QuizData.map((item, i) => (
                                item.question ?
                                <Box key={i}>
                                    <Divider variant="inset" component="li" sx={{
                                        margin: '0',
                                        padding: '0 !important'
                                    }} />
                                    <ListItem alignItems="flex-start">
                                        <ListItemText
                                            primary={<Typography
                                                sx={{ display: 'inline' }}
                                                component="span"
                                                variant="body1"
                                                gutterBottom
                                                color="text.primary"
                                            >
                                                {item.question}
                                            </Typography>}
                                            secondary={
                                                <React.Fragment>
                                                    <Typography
                                                        sx={{ display: 'inline' }}
                                                        component="span"
                                                        variant="body2"
                                                        color="text.primary"
                                                    >
                                                        Ans:&nbsp;
                                                    </Typography>
                                                    {typeof item.answer === 'number' ? item.answer == 0 ? 'No' : 'Yes' : item.answer}
                                                </React.Fragment>
                                            }
                                        />
                                    </ListItem>
                                </Box> : null
                            ))
                        ) : null
                    }
                </List>
                <List sx={{
                    width: '100%', bgcolor: 'background.paper', border: '2px solid pink',
                    padding: '8px',
                    margin: '16px 0',
                    borderRadius: '8px'
                }}>
                    <Box>
                        <Typography variant="body1" component="div" sx={{
                            flexGrow: 1, textAlign: 'center',
                            fontSize: '20px',
                            fontWeight: 'bold'
                        }}>
                            This set of questions are for Prospective or Current Partner
                        </Typography>
                    </Box>
                    <ListItem alignItems="flex-start">
                        <ListItemText
                            primary={<Typography
                                sx={{ display: 'inline' }}
                                component="span"
                                variant="body1"
                                gutterBottom
                                color="text.primary"
                            >
                                Name of your prospective or current partner?
                            </Typography>}
                            secondary={
                                <React.Fragment>
                                    <Typography
                                        sx={{ display: 'inline' }}
                                        component="span"
                                        variant="body2"
                                        color="text.primary"
                                    >
                                        Ans:&nbsp;
                                    </Typography>
                                    {questionData['person2_name']}
                                </React.Fragment>
                            }
                        />
                    </ListItem>
                    <Divider variant="inset" component="li" sx={{
                        margin: '0',
                        padding: '0 !important'
                    }} />
                    <ListItem alignItems="flex-start">
                        <ListItemText
                            primary={<Typography
                                sx={{ display: 'inline' }}
                                component="span"
                                variant="body1"
                                gutterBottom
                                color="text.primary"
                            >
                                Where were the person born?
                            </Typography>}
                            secondary={
                                <React.Fragment>
                                    <Typography
                                        sx={{ display: 'inline' }}
                                        component="span"
                                        variant="body2"
                                        color="text.primary"
                                    >
                                        Ans:&nbsp;
                                    </Typography>
                                    {questionData['person2_pob']}
                                </React.Fragment>
                            }
                        />
                    </ListItem>
                    <Divider variant="inset" component="li" sx={{
                        margin: '0',
                        padding: '0 !important'
                    }} />
                    <ListItem alignItems="flex-start">
                        <ListItemText
                            primary={<Typography
                                sx={{ display: 'inline' }}
                                component="span"
                                variant="body1"
                                gutterBottom
                                color="text.primary"
                            >
                                What is that person date of birth?
                            </Typography>}
                            secondary={
                                <React.Fragment>
                                    <Typography
                                        sx={{ display: 'inline' }}
                                        component="span"
                                        variant="body2"
                                        color="text.primary"
                                    >
                                        Ans:&nbsp;
                                    </Typography>
                                    {Moment(questionData['person2_dob']).format('DD-MMM-YYYY')}
                                </React.Fragment>
                            }
                        />
                    </ListItem>
                    <Divider variant="inset" component="li" sx={{
                        margin: '0',
                        padding: '0 !important'
                    }} />
                    <ListItem alignItems="flex-start">
                        <ListItemText
                            primary={<Typography
                                sx={{ display: 'inline' }}
                                component="span"
                                variant="body1"
                                gutterBottom
                                color="text.primary"
                            >
                                What is that person biological gender?
                            </Typography>}
                            secondary={
                                <React.Fragment>
                                    <Typography
                                        sx={{ display: 'inline' }}
                                        component="span"
                                        variant="body2"
                                        color="text.primary"
                                    >
                                        Ans:&nbsp;
                                    </Typography>
                                    {questionData['person2_gender'] === 'M' ? 'Male' : 'Female'}
                                </React.Fragment>
                            }
                        />
                    </ListItem>
                    {
                        person2QuizData ? (
                            person2QuizData.map((item, i) => (
                                item.question ?
                                <Box key={i}>
                                    <Divider variant="inset" component="li" sx={{
                                        margin: '0',
                                        padding: '0 !important'
                                    }} />
                                    <ListItem alignItems="flex-start">
                                        <ListItemText
                                            primary={<Typography
                                                sx={{ display: 'inline' }}
                                                component="span"
                                                variant="body1"
                                                gutterBottom
                                                color="text.primary"
                                            >
                                                {item.question}
                                            </Typography>}
                                            secondary={
                                                <React.Fragment>
                                                    <Typography
                                                        sx={{ display: 'inline' }}
                                                        component="span"
                                                        variant="body2"
                                                        color="text.primary"
                                                    >
                                                        Ans:&nbsp;
                                                    </Typography>
                                                    {typeof item.answer === 'number' ? item.answer == 0 ? 'No' : 'Yes' : item.answer}
                                                </React.Fragment>
                                            }
                                        />
                                    </ListItem>
                                </Box> : null
                            ))
                        ) : null
                    }
                </List>
            </Box>
        </Box>
    );
}