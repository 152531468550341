import Moment from "moment";
import { ccDefaultQuestion, defaultQuestion, Global } from "../global";


const isDate = (date) => {
  if (!date) return false;
  return new Date(date) !== "Invalid Date" && !isNaN(new Date(date));
};

const toDate = (x) => {
  return isDate(x) ? new Date(x) : x;
}

// updated 2022.07.23
const getQuestionData = () => {
  const LocalQuestionData = localStorage.getItem("quizdata");
  const questionNotApplicable = getQuestionNAData();
  const defaultQuestions = defaultQuestion();
  if (LocalQuestionData) {
    const LocalQuestionDataObject = JSON.parse(LocalQuestionData);
    if (LocalQuestionDataObject) {
      Object.entries(defaultQuestions).forEach((question) => {
        const key = question[0];
        let value = question[1];
        if (key in LocalQuestionDataObject) {
          defaultQuestions[key] = questionNotApplicable[key] ? defaultQuestions[key] : LocalQuestionDataObject[key];
          value = LocalQuestionDataObject[key];
        }
        if (isDate(value)) {
          defaultQuestions[key] = new Date(value);
        } else if (value instanceof Array) {
          value.forEach((data, index) => {
            if (data instanceof Array) {
              value[index][0] = toDate(value[index][0]);
              value[index][1] = toDate(value[index][1]);
            } else if (isDate(data)) {
              value[index] = toDate(data);
            }
          });
        }
      });
    }
  }
  return defaultQuestions;
}

const getCcQuestionData = () => {
  const LocalQuestionData = localStorage.getItem("quizdata");
  const defaultQuestions = ccDefaultQuestion();
  if (LocalQuestionData) {
    const LocalQuestionDataObject = JSON.parse(LocalQuestionData);
    if (LocalQuestionDataObject) {
      Object.entries(defaultQuestions).forEach((question) => {
        const key = question[0];
        let value = question[1];
        if (key in LocalQuestionDataObject) {
          defaultQuestions[key] = LocalQuestionDataObject[key];
          value = LocalQuestionDataObject[key];
        }
        if (Moment.isDate(value)) {
          defaultQuestions[key] = new Date(value);
        }
        //  else if (value instanceof Array) {
        //   value.forEach((data, index) => {
        //     if (data instanceof Array) {
        //       value[index][0] = toDate(value[index][0]);
        //       value[index][1] = toDate(value[index][1]);
        //     } else if (isDate(data)) {
        //       value[index] = toDate(data);
        //     }
        //   });
        // }
      });
    }
  }
  return defaultQuestions;
}

const getQuestionNAData = () => {
  const localQuestionNA = localStorage.getItem("questionNotApplicableData");
  if (localQuestionNA && localQuestionNA !== undefined && localQuestionNA !== null) {
    const localQuestionNAObject = JSON.parse(localQuestionNA);
    if (localQuestionNAObject) {
      return localQuestionNAObject;
    }
  }
  return {};
}

const validateDateRange = (start, end, validationMessages, desc, skipDayLimit) => {
  var now = new Date();
  if (isDate(start)) {
    if (start > now) {
      validationMessages.push(
        `${desc} invalid : start date ${start.toLocaleDateString()} cannot be in future`
      );
    }
  }
  if (isDate(end)) {
    if (end > now) {
      validationMessages.push(
        `${desc} invalid : end date ${end.toLocaleDateString()} cannot be in future`
      );
    }
  }
  if (!isDate(start) && isDate(end)) {
    validationMessages.push(
      `${desc} invalid : need to enter start date for the end date ${end.toLocaleDateString()}`
    );
  }
  if (isDate(start) && isDate(end)) {
    if (end < start) {
      validationMessages.push(
        `${desc} invalid : start date ${start.toLocaleDateString()} should be before the end date ${end.toLocaleDateString()}`
      );
    } else {
      if (!skipDayLimit) {
        const diffms = end.getTime() - start.getTime();
        const days = diffms / (1000 * 60 * 60 * 24);
        if (days > 31) {
          validationMessages.push(
            `${desc} invalid: select a smaller window for ${start.toLocaleDateString()} to ${end.toLocaleDateString()} (should be less than 31 days)`
          );
        }
      }
    }
  }
}

const computeProgressPercent = (questionData, questionNotApplicable = {}) => {
  var multiChoice = [
    Global.question.CarColor,
    Global.question.FavTaste,
    Global.question.TravelFrequency,
    Global.question.CareerType,
    Global.question.DayNight,
    Global.question.PersonalLife,
    Global.question.PeopleRelationship,
    Global.question.Gender,
  ]
  let multiChoiceAnswered = 0;
  let datesAnswered = 0;
  multiChoice.forEach((elem) => {
    const options = questionData[elem];
    if (options.some(item => item)) {
      multiChoiceAnswered++;
    }
    //   for (const [key, value] of Object.entries(options)) {
    //     if (value === true) {
    //       multiChoiceAnswered++;
    //       break;
    //     }
    //   }
  })

  const dateQuestions = [
    Global.question.dob,
    Global.question.Mdb,
    Global.question.Fdb,
  ]
  const dateArrQuestions = [
    Global.question.Brdb,
    Global.question.Srdb,
    Global.question.Sodb,
    Global.question.Ddb,
    Global.question.Spdb,
    Global.question.Frdb,
  ]

  const dateArrRangeQuestions = [
    Global.question.PLgr,
    Global.question.Ede,
    Global.question.Cs,
    Global.question.Csb,
    Global.question.Wn,
    Global.question.Wo,
  ]
  dateArrRangeQuestions.forEach((elem) => {
    let dates = questionData[elem];
    if (questionNotApplicable[elem] === true) {
      datesAnswered++;
    }
    else if (dates) {
      let anyDateAnswered = null;
      dates.forEach((dt) => {
        if (dt !== null && dt !== undefined) {
          anyDateAnswered = true;
        }
      });
      if (anyDateAnswered === true) {
        datesAnswered++;
      }
    }
  });

  dateArrQuestions.forEach((elem) => {
    let dates = questionData[elem];
    if (questionNotApplicable[elem] === true) {
      datesAnswered++;
    }
    else if (dates) {
      let anyDateAnswered = null;
      dates.forEach((dt) => {
        if (dt !== null && dt !== undefined) {
          anyDateAnswered = true;
        }
      });
      if (anyDateAnswered === true) {
        datesAnswered++;
      }
    }
  });
  dateQuestions.forEach((x) => {
    const dt = questionData[x];
    if (questionNotApplicable[x] === true) {
      datesAnswered++;
    }
    else if (dt !== null && dt !== undefined) {
      datesAnswered++;
    }
  });

  if (questionData.pob && questionData.pob.trim().length > 0) {
    datesAnswered++;
  }

  const datepc = datesAnswered / (dateQuestions.length + dateArrQuestions.length + dateArrRangeQuestions.length + 1);
  const multipc = multiChoiceAnswered / (multiChoice.length);
  let progress = parseInt(Math.round(70 * multipc + 30 * datepc));

  return progress;
}

const getSortedCategory = (purchase = []) => {
  var obj = purchase.map((c) => { return [Global.categorySortOrder[c.category], c]; });
  var sortedCategory = obj.sort((a, b) => { return a[0] - b[0] }).map((x) => { return x[1] });
  return sortedCategory;
}

const getDateInt = (d) => {
  if (isDate(d)) {
    const dt = new Date(d);
    const dint =
      dt.getFullYear() * 10000 + (dt.getMonth() + 1) * 100 + dt.getDate();
    // console.log("getDateInt HIT", d, dint);
    return dint;
  }
  return d;
}

const getSortedSubCategory = (subcategories = []) => {
  var obj = subcategories.map((sc) => { return [Global.subcategorySortOrder[sc.code], sc]; });
  var sortedSubcategory = obj.sort((a, b) => { return a[0] - b[0] }).map((x) => { return x[1] });
  return sortedSubcategory;
}

const getPayload = (questionData, questionNotApplicable) => {
  const qd = questionData;
  const defq = defaultQuestion();
  let pl = JSON.parse(JSON.stringify(qd));
  Object.entries(pl).forEach((x) => {
    const k = x[0];
    const v = x[1];
    if (questionNotApplicable[k] === true) {
      pl[k] = defq[k];
    }
    else if (isDate(v)) {
      pl[k] = getDateInt(v);
    } else if (v instanceof Array) {
      v.forEach((y, j) => {
        if (y instanceof Array) {
          v[j][0] = getDateInt(v[j][0]);
          v[j][1] = getDateInt(v[j][1]);
        } else if (isDate(y)) {
          v[j] = getDateInt(y);
        }
      });
    }
  });
  if (pl.Gender[Global.BiologicalMale] === true) {
    pl[Global.question.Gender] = "M";
  } else if (pl.Gender[Global.BiologicalFemale] === true) {
    pl[Global.question.Gender] = "F";
  } else {
    pl[Global.question.Gender] = "-";
  }

  // console.log("payload clone", pl);
  return pl;
}

export { isDate, toDate, getQuestionData,getCcQuestionData, getQuestionNAData, validateDateRange, computeProgressPercent, getSortedCategory, getSortedSubCategory, getPayload }
