export const themeOptions = {
    palette: {
        type: 'light',
        primary: {
            light: '#FFC233',
            main: '#b99a38',
            dark: '#B27D00',
            contrastText: 'rgba(0, 0, 0, 0.87)',
        },
        secondary: {
            main: '#00B5F6',
        },
    },
    typography: {
        fontFamily: 'Montserrat'
    }
};