// ▽

import React, { Component, forwardRef, useState } from "react";
import { withRouter } from "react-router";
import { Alert, Backdrop, Box, Button, Card, CardContent, Checkbox, FormControlLabel, FormGroup, IconButton, OutlinedInput, Paper, Snackbar, Tooltip, Typography, Zoom } from '@mui/material';
import AddCircleOutlinedIcon from '@mui/icons-material/AddCircleOutlined';
import RemoveCircleOutlinedIcon from '@mui/icons-material/RemoveCircleOutlined';
import { orange } from '@mui/material/colors';
import ChevronLeftOutlinedIcon from '@mui/icons-material/ChevronLeftOutlined';
import ChevronRightOutlinedIcon from '@mui/icons-material/ChevronRightOutlined';
import ExpandLessRoundedIcon from '@mui/icons-material/ExpandLessRounded';
import { PureComponent } from 'react';

import { Suspense } from "react";
import { useEffect } from "react";
import { deleteDynamicQuestions, saveAllQuestions, saveAllQuestionsToLocal, saveComputeData, saveDynamicQuestions, toggleOptionalQuestions } from "../redux/actions/appActions";
import AssessmentAgreement from "./v2/AssessmentAgreement";
import { useDispatch, useSelector } from "react-redux";
import { Global, ccDefaultQuestion } from "../global";
import Loader from "./v2/Loader";
import { memo } from "react";

import { jsPDF } from "jspdf"; // added 2023.02.17
import html2canvas from 'html2canvas';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import TextField from '@mui/material/TextField';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import InputAdornment from '@mui/material/InputAdornment';
import Input from '@mui/material/Input';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import CircularProgressWithLabel from '@mui/material/CircularProgress';
import CCAssessmentAgreement from "./v2/CCAssessmentAgreement";
import StaticComponent from "./v2/StaticComponent";
import GeoLocationCC from "./v2/GeoLocationCC";
import axios from "axios";
import RefreshIcon from "@mui/icons-material/Refresh";
import { List } from "immutable";
import CcGridComponent from "./v2/CcGridComponent";
import { getCcQuestionData, getQuestionData } from "../redux/Helper";
import CircularProgress from '@mui/material/CircularProgress';
import ShowSummary from "./ShowSummary";
import * as _ from "lodash";
import { Prompt } from "react-router-dom";
import SecurityCheck from "./SecurityCheck";
const GridComponent = React.lazy(() => import("./v2/GridComponent"));
const ComputeBar = React.lazy(() => import("./v2/ComputeBar"));
const GeoLocation = React.lazy(() => import("./v2/GeoLocation"));
const StackComponent = React.lazy(() => import("./v2/StackComponent"));
const StackDateComponent = React.lazy(() => import("./v2/StackDateComponent"));
const gq = Global.ccQuestion;

const CoupleCompatibility = ({ history, refreshQuestionData, purchaseChanged, questionData, showSummaryStore, affiliatorCode , checksum, isBothReportSelected }) => {
  const dispatch = useDispatch();
  const ccAgreeement = useSelector(state => state.appReducer.ccAgreeement);
  const person1QuizData = useSelector(state => state.appReducer.person1_quiz_data);
  const person2QuizData = useSelector(state => state.appReducer.person2_quiz_data);
  // const currentStoreData = useSelector(state => state.appReducer);

  const [captcha, setCaptcha] = useState();
  const [captchaToken, setCaptchaToken] = useState();
  const [captchaMessage, setCaptchaMessage] = useState();
  const [verificationText, setVerificationText] = useState('');
  const [batch, setBatch] = useState(0);
  const [group, setGroup] = useState(0);
  const [showNext, setShowNext] = useState(false);
  const [keysCollector, setKeysCollector] = useState({});
  const [error, setError] = useState('');
  const [ccsid, setCcsid] = useState('');
  const [p1Finished, setP1Finished] = useState(false);
  const [p2Finished, setP2Finished] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [showSummary, setShowSummary] = useState(false);
  const [person1QuizDataState, setPerson1QuizDataState] = useState(null);
  const [person2QuizDataState, setPerson2QuizDataState] = useState(null);
  const [showSecurityCheck, setShowSecurityCheck] = React.useState(false);
  useEffect(() => {
    document.title = "BuddyNow - Couple Compatibility";
    if (window.location.hash) {
      document.querySelector(window.location.hash).scrollIntoView({
        behavior: 'smooth'
      });
    }
    loadCaptcha();
    return () => {
      const isSummaryExist = localStorage.getItem('quizdata') ? JSON.parse(localStorage.getItem('quizdata'))?.showSummary : false
      if (!isSummaryExist) {
        dispatch(deleteDynamicQuestions())
      }
      handleSave();
    };
  }, []);

  useEffect(() => {
    if (questionData) {
      const staticQuestions = _.pick(questionData, 'person1_dob', 'person1_gender', 'person1_pob', 'person1_name', 'person2_name', 'person2_pob', 'person2_gender', 'person2_dob')
      setKeysCollector({ ...staticQuestions })
    }
  }, [questionData]);

  useEffect(() => {
    if (showSummaryStore) {
      setShowSummary(true)
    }
  }, [showSummaryStore])

  useEffect(() => {
    if (List.isList(person1QuizData)) {
      setPerson1QuizDataState(person1QuizData.toJS());
    }
  }, [person1QuizData])
  useEffect(() => {
    if (List.isList(person2QuizData)) {
      setPerson2QuizDataState(person2QuizData.toJS());
    }
  }, [person2QuizData])

  const loadCaptcha = () => {
    setIsLoading(true)
    axios
      .get(`${process.env.REACT_APP_SERVER}/buddy/captcha`)
      .then((response) => {
        let d = response.data;
        setCaptchaMessage('');
        setVerificationText('');
        setCaptchaToken(d.token);
        setCaptcha(d.image)
        setIsLoading(false)
      })
      .catch((e) => {
        setVerificationText('');
        setIsLoading(false)
      });
  }

  const loadFirstPersonQuestion = (ccsid) => {
    let params = {
      cc_session_id: ccsid,
      person: p1Finished ? 2 : 1,

    }

    if (batch) {
      const list = p1Finished ? person2QuizData : person1QuizData;
      const answers = list.reduce(
        (previousObject, currentObject) => {
          return Object.assign(previousObject, {
            [currentObject.qid]: currentObject.answer
          })
        },
        {});
      params.batch = batch;
      params.group = group;
      params.answers = answers
    }
    setIsLoading(true)
    axios
      .post(`${process.env.REACT_APP_SERVER}/api/cc/load_cc_quiz`, params)
      .then((response) => {
        setKeysCollector({ ...{} })
        setBatch(response.data.next_batch);
        setGroup(response.data.next_group);
        dispatch(saveDynamicQuestions(p1Finished ? 'person2_quiz_data' : 'person1_quiz_data', response.data.questions))
        const questionSet = _.chain(response.data.questions)
          .keyBy('qid')
          .mapValues('')
          .value();
        //        setKeysCollector({...map(response.data.questions, e=> ({[e.qid]: ''}))});
        setKeysCollector({ ...questionSet });
        setIsLoading(false)
        if (response.data.finished) {
          if (!p1Finished) {
            setBatch(0);
            setGroup(0);
            setP1Finished(response.data.finished)
            dispatch(saveAllQuestions('person1_group_I', response.data.group_I));
            dispatch(saveAllQuestions('person1_group_II', response.data.group_II));
          } else {
            setBatch(0);
            setGroup(0);
            setP2Finished(response.data.finished)
            dispatch(saveAllQuestions('person2_group_I', response.data.group_I));
            dispatch(saveAllQuestions('person2_group_II', response.data.group_II));

            // setShowSummary(true);
          }
        }
        document.querySelector('#top').scrollIntoView({
          behavior: 'smooth'
        });
        setIsLoading(false)
      })
      .catch((e) => {
        setIsLoading(false)
      });
  }


  const handleSave = () => {
    dispatch(saveAllQuestionsToLocal());
    refreshQuestionData();
  }

  const handleQuestion = (qkey, value) => {
    setKeysCollector({ ...keysCollector, [qkey]: value })
  }

  useEffect(() => {
    // if(_.keys(_.pickBy(keysCollector, _.identity)).length == 8) {
    if (_.some(keysCollector, _.isEmpty)) {
      setShowNext(false)
    } else {
      setShowNext(true)
    }
  }, [keysCollector])

  const isDate = function (date) {
    if (!date) {
      return false;
    }
    return new Date(date) !== "Invalid Date" && !isNaN(new Date(date));
  };

  const getDateInt = (d) => {
    if (isDate(d)) {
      const dt = new Date(d);
      const dint =
        dt.getFullYear() * 10000 + (dt.getMonth() + 1) * 100 + dt.getDate();
      // console.log("getDateInt HIT", d, dint);
      return dint;
    }
    return d;
  }

  const handleSubmit = () => {
    setIsLoading(true)
    handleSave();
    // const params = _.pick(getCcQuestionData(), 'person1_dob', 'person1_gender', 'person1_geo', 'person2_dob', 'person2_gender', 'person2_geo')
    /*** Modifications 2023.11.20 for tool ***/
    const params = _.pick(getCcQuestionData(), 'person1_name', 'person1_dob', 'person1_gender', 'person1_geo', 'person2_name', 'person2_dob', 'person2_gender', 'person2_geo');
    params.affiliatorCode = affiliatorCode;
    /*** END Modifications ***/
    params["person1_dob"] = getDateInt(params.person1_dob);
    params["person2_dob"] = getDateInt(params.person2_dob);
    params["verification_text"] = verificationText; // captcha text entered by user
    params["captcha_token"] = captchaToken
    axios
      .post(`${process.env.REACT_APP_SERVER}/api/cc/initiate_cc_quiz`, params)
      .then((response) => {
        if (response.data.cc_session_id) {
          setCcsid(response.data.cc_session_id)
          loadFirstPersonQuestion(response.data.cc_session_id);
        } else {
          setError('Invalid CAPTCHA. Please try again.')
          setIsLoading(false)
          setVerificationText('');
        }
      })
      .catch((error) => {
        setIsLoading(false)
        setError('Something went wrong, please try again!')
      });
  }
  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setError('');
  };
  const returnCallback = value => {
    setShowSecurityCheck(value)
  }
  const isDynamicQuestionView = (person1QuizData && !person1QuizData.isEmpty()) || (person2QuizData && !person2QuizData.isEmpty());
  return (
    <SecurityCheck checksum={checksum} isBothReportSelected={isBothReportSelected} showSecurityCheck={showSecurityCheck} returnCallback={returnCallback}>
      <div className='self-assessment'>
        {
          ccAgreeement ?
            (<Box
              className="self-assessment--content"
              sx={{
                background: '#fff',
                borderRadius: 2,
                outline: 1,
                border: '1px solid #ddd',
                padding: 8
              }}
              id="top"
            >
              <div className='maincontent'>
                <Prompt when={isDynamicQuestionView && !showSummaryStore} message="Information you’ve entered may not be saved. Are you sure you want to leave?" />
                <Box sx={{ display: 'flex', justifyContent: 'space-around' }} >
                  {showSummary ?
                    <Tooltip title={'Edit my answers'} enterDelay={500} leaveDelay={200} TransitionComponent={Zoom} arrow>
                      <Button sx={{
                        color: '#fff',
                        backgroundColor: '#b99a38',
                        background: 'linear-gradient(90deg, #b99a38, #dfc779)',
                        textTransform: 'none',
                      }} onClick={(e) => {
                        const response = window.confirm('You will need to restart the entire submission to edit the fields you have answered.\nDo you want to continue editing?')
                        if (response) {
                          setCaptcha('')
                          setCaptchaToken('')
                          setCaptchaMessage('');
                          setVerificationText('');
                          setBatch(0);
                          setGroup(0);
                          setP1Finished(false)
                          setP2Finished(false)
                          setShowSummary(false)
                          setPerson1QuizDataState(null);
                          setPerson2QuizDataState(null);
                          dispatch(deleteDynamicQuestions())
                          loadCaptcha()
                          dispatch(saveAllQuestions('showSummary', 0));
                          handleSave();
                        }
                      }} variant="contained" title="Save your quiz answers!">Edit</Button>
                    </Tooltip>
                    : null}

                  {!isDynamicQuestionView || showSummary?
                    <Tooltip title={'Clear Couple Compatibility answers'} enterDelay={500} leaveDelay={200} TransitionComponent={Zoom} arrow>
                      <Button sx={{
                        textTransform: 'none',
                        border: '1px solid #b99a38',
                        borderColor: 'linear-gradient(90deg, #b99a38, #dfc779)',
                      }} variant="outlined" onClick={(e) => {

                        localStorage.setItem("quizdata", JSON.stringify(_.omit(JSON.parse(localStorage.getItem("quizdata")), _.keys(Global.ccQuestion))));
                        window.location.href = "/insights";
                      }}>Clear</Button>
                    </Tooltip>
                    : null}
                </Box>

                <Box className="question">
                  {
                    showSummary ? <ShowSummary questionData={questionData} /> :
                      person2QuizDataState && person2QuizDataState.length ? (
                        <>
                          <Box display="grid" idTemplateColumgrns="repeat(12, 1fr)" gap={2} sx={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'space-around',
                            padding: '16px'
                          }} className="make-portrait">
                            <Typography variant="h6" sx={{
                              textAlign: 'center',
                              padding: '40px 0 10px',
                              maxWidth: '750px',
                              fontSize: '23px'
                            }}>
                              This set of questions are for your Prospective or Current Partner. Questions color coded in <span style={{ color: 'pink', fontWeight: 'bold' }}>pink</span>.
                            </Typography>
                          </Box>
                          {
                            person2QuizDataState.map((question, index) => {
                              return !_.has(keysCollector, question.qid) ? null : (
                                <Box className="toplevelquestion toplevelquestion_none" key={index}>
                                  <Suspense fallback={<Loader />}>
                                    <CcGridComponent question={question} questionKey={'person2_quiz_data'} isPinkBackground={true} handleQuestion={handleQuestion} />
                                  </Suspense>
                                </Box>
                              )

                            })
                          }
                        </>
                      ) : person1QuizDataState && person1QuizDataState.length ? (
                        <>
                          <Box display="grid" idTemplateColumgrns="repeat(12, 1fr)" gap={2} sx={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'space-around',
                            padding: '16px'
                          }} className="make-portrait">
                            <Typography variant="h6" sx={{
                              maxWidth: '700px',
                              textAlign: 'center',
                              padding: '40px 0 10px',
                              maxWidth: '450px',
                              fontSize: '23px'
                            }}>
                              This set of questions are for You. Questions color coded in <span style={{ color: '#f00', fontWeight: 'bold' }}>red</span>.
                            </Typography>
                          </Box>
                          {
                            person1QuizDataState.map((question, index) => {
                              return !_.has(keysCollector, question.qid) ? null : (
                                <Box className="toplevelquestion toplevelquestion_none" key={index}>
                                  <Suspense fallback={<Loader />}>
                                    <CcGridComponent question={question} questionKey={'person1_quiz_data'} handleQuestion={handleQuestion} />
                                  </Suspense>
                                </Box>
                              )
                            })
                          }
                        </>
                      ) : (
                        <>
                          <Box display="grid" idTemplateColumgrns="repeat(12, 1fr)" gap={2} sx={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'space-around',
                            padding: '16px'
                          }} className="make-portrait">
                            <Typography variant="h6" sx={{
                              maxWidth: '700px',
                              textAlign: 'center',
                              padding: '40px 0 10px',
                              maxWidth: '450px',
                              fontSize: '23px'
                            }}>
                              This set of questions are for You. Questions color coded in <span style={{ color: '#f00', fontWeight: 'bold' }}>red</span>.
                            </Typography>
                          </Box>
                          <Box className="toplevelquestion toplevelquestion_none">
                            <Suspense fallback={<Loader />}>
                              <StaticComponent qkey={'k1'} questionData={questionData} questionKey={gq.person1_name} questionText="Simply put any name/nickname to identify yourself." subText={'Note: The name is for your own reference, not used for generating the report.'} answerType="text" handleQuestion={handleQuestion} />
                            </Suspense>
                          </Box>
                          <Box className="toplevelquestion toplevelquestion_none">
                            <Suspense fallback={<Loader />}>
                              <GeoLocationCC qkey={'k2'} questionKey={gq.person1_geo} questionKey1={gq.person1_pob} questionText="Where were you born?" subText={'Search for your location of birth and select the option from the drop-down list. (Ideal if you can remember the exact location e.g., Hospital Name)'} answerType="text" handleQuestion={handleQuestion} checksum={checksum} history={history}  returnCallback={returnCallback}/>
                            </Suspense>
                          </Box>
                          <Box className="toplevelquestion toplevelquestion_none">
                            <Suspense fallback={<Loader />}>
                              <StaticComponent qkey={'k3'} qkey1={'k4'} questionKey={gq.person1_dob} questionKey1={gq.person1_gender} questionText="What is your date of birth and biological gender?" answerType="date" handleQuestion={handleQuestion} />
                            </Suspense>
                          </Box>
                          <Box display="grid" idTemplateColumgrns="repeat(12, 1fr)" gap={2} sx={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'space-around',
                            padding: '16px'
                          }} className="make-portrait">
                            <Typography variant="h6" sx={{
                              textAlign: 'center',
                              padding: '40px 0 10px',
                              maxWidth: '750px',
                              fontSize: '23px'
                            }}>
                              This set of questions are for your Prospective or Current Partner. Questions color coded in <span style={{ color: 'pink', fontWeight: 'bold' }}>pink</span>.
                            </Typography>
                          </Box>
                          <Box className="toplevelquestion toplevelquestion_none">
                            <Suspense fallback={<Loader />}>
                              <StaticComponent qkey={'k5'} questionData={questionData} questionKey={gq.person2_name} questionText="Simply put any name/nickname to identify your prospective or current partner." subText={'Note: The name is for your own reference, not used for generating the report.'} answerType="text" isPinkBackground={true} handleQuestion={handleQuestion} />
                            </Suspense>
                          </Box>
                          <Box className="toplevelquestion toplevelquestion_none">
                            <Suspense fallback={<Loader />}>
                              <GeoLocationCC qkey={'k6'} questionKey={gq.person2_geo} questionKey1={gq.person2_pob} questionText="Where were the person born?" subText={'Search for your location of birth and select the option from the drop-down list. (Ideal if you can remember the exact location e.g., Hospital Name)'} answerType="text" isPinkBackground={true} handleQuestion={handleQuestion} checksum={checksum} history={history}  returnCallback={returnCallback}/>
                            </Suspense>
                          </Box>
                          <Box className="toplevelquestion toplevelquestion_none">
                            <Suspense fallback={<Loader />}>
                              <StaticComponent qkey={'k7'} qkey1={'k8'} questionKey={gq.person2_dob} questionKey1={gq.person2_gender} questionText="What is that person date of birth and biological gender?" answerType="date" isPinkBackground={true} handleQuestion={handleQuestion} />
                            </Suspense>
                          </Box>
                          <Box display="grid" idTemplateColumgrns="repeat(12, 1fr)" gap={2} sx={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'space-around',
                            padding: '16px',
                            mt: 2
                          }} className="make-portrait">
                            <Typography variant="body1" >
                              As a protection against spam, type in the words that appear in this image:
                            </Typography>
                          </Box>
                          <Box display="grid" idTemplateColumgrns="repeat(12, 1fr)" gap={2} sx={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'space-around',
                            padding: '16px'
                          }} className="make-portrait">
                            <Box gridColumn="span 12">
                              {captcha ? (
                                <img src={`data:image/png;base64,${captcha}`} className="capcha-img" />
                              ) : (
                                ""
                              )}
                            </Box>
                            <Box gridColumn="span 12">
                              <FormControl sx={{ m: 1, width: '25ch' }} variant="outlined">
                                <InputLabel htmlFor="outlined-adornment-password">Type in the words</InputLabel>
                                <OutlinedInput
                                  id="outlined-adornment-password"
                                  value={verificationText}
                                  onChange={(e) => {
                                    // this.setState({
                                    //   verificationText: e.target.value.toUpperCase(),
                                    // });
                                    setVerificationText(e.target.value.toUpperCase());
                                  }}
                                  size={"medium"}
                                  endAdornment={
                                    <a href="#">
                                      <RefreshIcon
                                        onClick={() => {
                                          loadCaptcha();
                                        }}
                                      />
                                    </a>
                                  }
                                  label="Type in the words"
                                />
                              </FormControl>

                            </Box>
                            <div>
                              {captchaMessage ? (
                                <Alert severity="error">{captchaMessage}</Alert>
                              ) : (
                                ""
                              )}
                            </div>
                          </Box>
                        </>
                      )
                  }
                  <Snackbar open={error} autoHideDuration={6000} onClose={handleClose} color="red">
                    <Alert onClose={handleClose} severity="error" sx={{ width: '100%' }}>
                      {error}
                    </Alert>
                  </Snackbar>
                  {error && <Box display="grid" idTemplateColumgrns="repeat(12, 1fr)" gap={2} sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-around',
                    padding: '16px'
                  }} className="make-portrait">
                    <Typography variant="h6" color={"red"}>
                      {error}
                    </Typography>
                  </Box>}
                  <Box sx={{
                    flexGrow: 1, display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    padding: '16px 0',
                    position: 'relative',
                    margin: '40px auto'
                  }} className="buynow-btn">
                    {
                      // isLoading ? <Box sx={{ display: 'flex' }}>
                      //   <CircularProgress />
                      // </Box> :
                      <>
                        <Backdrop
                          sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                          open={isLoading}
                        >
                          <CircularProgress color="primary" sx={{
                            width: 250,
                            height: 250,
                            color: '#73FF79'
                          }} size={100} />
                        </Backdrop>
                        {
                          isDynamicQuestionView ?
                            showSummary ?
                              <Box>
                                <Button sx={{
                                  color: '#fff',
                                  backgroundColor: '#b99a38',
                                  background: 'linear-gradient(90deg, #b99a38, #dfc779)',
                                  border: '2px solid',
                                  borderColor: '#b99a38',
                                  fontWeight: 800,
                                  fontSize: '20px',
                                  lineHeight: '24px',
                                  textTransform: 'none',
                                  width: '300px',
                                  // '&:hover': {
                                  //   backgroundColor: orange[500],
                                  // }
                                }} variant="contained" onClick={(e) => {
                                  if(!affiliatorCode) {
                                    purchaseChanged('cc', true, "RELATIONSHIPS")
                                  }
                                  history.push('/cart#top')
                                }} id="optq">
                                  Proceed to Checkout
                                </Button>
                              </Box>
                              :
                              p2Finished ?
                                <Tooltip title={(isDynamicQuestionView && !showNext) ? 'Please answers all questions to proceed' : ''} enterDelay={500} leaveDelay={200} TransitionComponent={Zoom} arrow>
                                  <span>
                                    <Button disabled={(isDynamicQuestionView && !showNext)} sx={{
                                      color: '#fff',
                                      backgroundColor: '#b99a38',
                                      background: 'linear-gradient(90deg, #b99a38, #dfc779)',
                                      border: '2px solid',
                                      borderColor: '#b99a38',
                                      fontWeight: 800,
                                      fontSize: '20px',
                                      lineHeight: '24px',
                                      textTransform: 'none',
                                      width: '300px',
                                      // '&:hover': {
                                      //   backgroundColor: orange[500],
                                      // }
                                    }} variant="contained" onClick={(e) => {
                                      dispatch(saveAllQuestions('showSummary', 1));
                                      handleSave();
                                    }} id="optq">
                                      Next
                                    </Button>
                                  </span>
                                </Tooltip> :
                                <Tooltip title={(isDynamicQuestionView && !showNext) ? 'Please answers all questions to proceed' : ''} enterDelay={500} leaveDelay={200} TransitionComponent={Zoom} arrow>
                                  <span>
                                    <Button disabled={(isDynamicQuestionView && !showNext)} sx={{
                                      color: '#fff',
                                      backgroundColor: '#b99a38',
                                      background: 'linear-gradient(90deg, #b99a38, #dfc779)',
                                      border: '2px solid',
                                      borderColor: '#b99a38',
                                      fontWeight: 800,
                                      fontSize: '20px',
                                      lineHeight: '24px',
                                      textTransform: 'none',
                                      width: '300px',
                                      // '&:hover': {
                                      //   backgroundColor: orange[500],
                                      // }
                                    }} variant="contained" onClick={(e) => {
                                      loadFirstPersonQuestion(ccsid);
                                    }} id="optq">
                                      Next
                                    </Button>
                                  </span>
                                </Tooltip> :
                                <Tooltip title={!(showNext && verificationText) ? 'Please answers all questions to proceed' : ''} enterDelay={500} leaveDelay={200} TransitionComponent={Zoom} arrow>
                                  <span>
                                  <Button disabled={!(showNext && verificationText)} sx={{
                              color: '#fff',
                              backgroundColor: '#b99a38',
                              background: 'linear-gradient(90deg, #b99a38, #dfc779)',
                              border: '2px solid',
                              borderColor: '#b99a38',
                              fontWeight: 800,
                              fontSize: '20px',
                              lineHeight: '24px',
                              textTransform: 'none',
                              width: '300px',
                              // '&:hover': {
                              //   backgroundColor: orange[500],
                              // }
                            }} variant="contained" onClick={(e) => {
                              handleSubmit()
                              //handleSubmit()
                            }} id="optq">
                              Confirm
                            </Button>
                                  </span>
                                </Tooltip>
                        }







                        {/* <Button disabled={isLoading || (!p2Finished) ||(isDynamicQuestionView && !showNext) || (!(showNext && verificationText))} sx={{
                          color: '#fff',
                          backgroundColor: '#b99a38',
                          background: 'linear-gradient(90deg, #b99a38, #dfc779)',
                          border: '2px solid',
                          borderColor: '#b99a38',
                          fontWeight: 800,
                          fontSize: '20px',
                          lineHeight: '24px',
                          textTransform: 'none',
                          width: '300px',
                          // '&:hover': {
                          //   backgroundColor: orange[500],
                          // }
                        }} variant="contained" onClick={(e) => {
                          if(showSummary) {
                            purchaseChanged('cc', true, "RELATIONSHIPS")
                            history.push('/cart#top')
                          } else if (p2Finished) {
                            //setShowSummary(true);
                            dispatch(saveAllQuestions('showSummary', 1));
                            handleSave();
                          } else if(isDynamicQuestionView) {
                            loadFirstPersonQuestion(ccsid);
                          }
                          else {
                            handleSubmit()
                          }
                          //handleSubmit()
                        }} id="optq">
                          {showSummary ? 'Proceed to Checkout' : p2Finished ? 'Show summary' : isDynamicQuestionView ? 'Next' : 'Submit'}
                        </Button> */}
                      </>
                    }

                  </Box>
                </Box>
              </div>
            </Box>) :
            <div className='maincontent'>
              <CCAssessmentAgreement />
            </div>
        }
      </div>
    </SecurityCheck>

  )
}

export default withRouter(memo(CoupleCompatibility));
