import { actionTypes } from "../constants/actionTypes";

export const saveAllQuestions = (key, data) => {
    return {
        type: actionTypes.SAVE_QUESTIONS,
        payload: { key, data }
    }
}

export const saveAllQuestionsToLocal = () => {
    return {
        type: actionTypes.SAVE_QUESTIONS_TO_LOCAL,
        payload: {}
    }
}

export const saveNAQuestions = (key, data) => {
    return {
        type: actionTypes.SAVE_NA_QUESTIONS,
        payload: { key, data }
    }
}

export const savePurchaseReportsData = data => {
    return {
        type: actionTypes.SAVE_PURCHASE_REPORTS_DATA,
        payload: data
    }
}

export const updatePurchaseReportsData = data => {
    return {
        type: actionTypes.UPDATE_PURCHASE_REPORTS_DATA,
        payload: data
    }
}

export const saveTrialReportsData = data => {
    return {
        type: actionTypes.SAVE_TRIAL_REPORTS_DATA,
        payload: data
    }
}

export const saveMaintenanceData = data => {
    return {
        type: actionTypes.SAVE_MAINTENANCE_DATA,
        payload: data
    }
}

export const saveComputeData = () => {
    return {
        type: actionTypes.SAVE_COMPUTATION,
        payload: {}
    }
}

export const saveTrial = (data) => {
    return {
        type: actionTypes.SAVE_TRIAL,
        payload: data
    }
}

export const saveAffiliatorCode = (data) => {
    return {
        type: actionTypes.SAVE_AFFILIATOR_CODE,
        payload: data
    }
}

export const saveAssessmentAgreement = (agreement) => {
    return {
        type: actionTypes.SAVE_ASSESSMENT_AGREEMENT,
        payload: agreement
    }
}

export const saveCoupleCompatibilityAgreement = (agreement) => {
    return {
        type: actionTypes.SAVE_COUPLE_COMPATIBILITY_AGREEMENT,
        payload: agreement
    }
}

export const toggleOptionalQuestions = (optional) => {
    return {
        type: actionTypes.TOGGLE_OPTIONAL_QUESTIONS,
        payload: optional
    }
}

export const saveStaticQuestions = () => {
    return {
        type: actionTypes.SAVE_STATIC_QUESTION,
        payload: {}
    }
}

export const saveDynamicQuestions = (key, data) => {
    return {
        type: actionTypes.SAVE_DYNAMIC_QUESTIONS,
        payload: { key, data }
    }
}

export const deleteDynamicQuestions = () => {
    return {
        type: actionTypes.DELETE_DYNAMIC_QUESTIONS,
        payload: { }
    }
}

export const updateDynamicQuestion = (key, data) => {
    return {
        type: actionTypes.UPDATE_DYNAMIC_QUESTIONS,
        payload: { key, data }
    }
}


// export const savePerson1Questions = (key, data) => {
//     return {
//         type: actionTypes.SAVE_PERSON1_QUESTIONS,
//         payload: { key, data }
//     }
// }

// export const savePerson2Questions = (key, data) => {
//     return {
//         type: actionTypes.SAVE_PERSON2_QUESTIONS,
//         payload: { key, data }
//     }
// }