import React from "react";
import Geolookup from "react-geolookup-v2";
import { useDispatch, useSelector } from "react-redux";
import * as Nominatim from "nominatim-browser";
import { Box, Grid, Paper, Typography } from "@mui/material";
import { styled } from '@mui/material/styles';
import { saveAllQuestions, saveComputeData } from "../../redux/actions/appActions";
import { memo } from "react";
import { defaultQuestion, Global, ccDefaultQuestion } from "../../global";
import { useState } from "react";

const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'center',
    height: 150,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    position: 'relative',
    color: theme.palette.text.primary,
  }));
const GeoLocationCC = ({ questionKey, questionKey1, handleQuestion, isPinkBackground, checksum, history, returnCallback}) => {
    const dispatch = useDispatch();
    const placeOfBirth = useSelector(state => state.appReducer[questionKey1]);
    const [placeNotFound, setPlaceNotFound] = useState([]);

    const customUrl =  `${process.env.REACT_APP_SERVER}/api/geo`;
    /* FOR POINTING TO ON PREM ENABLE THE LINE BELOW AND COMMENT THE LINE ABOVE */
    // const customUrl = `${process.env.REACT_APP_SERVER}/api/geo`

    const onSuggestsLookup = async (userInput) => {
        // setSearchResultsDisplay(false);
        let response = [];
        try {
            if(userInput) {
                response = userInput ? await Nominatim.geocode({
                    q: userInput,
                    cs: checksum,
                    addressdetails: true,
                }, customUrl) : [];
            }
        } catch(e) {
            if(e.message.includes(403)) {
                localStorage.setItem('securityCheckPassed', false)
                //localStorage.setItem('attemptCount', 0)
                returnCallback(true)
                //history?.push('/cc')
                //window.location.reload();
            }

        }


        return response
    }

    const onGeocodeSuggest = (suggest) => {
        let geocoded = {};
        if (suggest) {
            geocoded.nominatim = suggest.raw || {};
            geocoded.location = {
                lat: suggest.raw ? suggest.raw.lat : "",
                lon: suggest.raw ? suggest.raw.lon : "",
            };
            geocoded.placeId = suggest.placeId;
            geocoded.isFixture = suggest.isFixture;
            geocoded.label = suggest.raw ? suggest.raw.display_name : "";
        }
        return geocoded;
    }


    const getSuggestLabel = (suggest) => {
        return suggest.display_name;
    }

    return (
        <Box>
            <Grid container spacing={2}
                direction="row"
                justifyContent="center"
                alignItems="center">
                <Grid item xs={12} zeroMinWidth>
                    <Item style={{
                        background: isPinkBackground ? '#FE90AA' : '#FA6C6C' 
                    }}>
                        <Box>
                            <Typography variant="h5" >
                                Where were you born?
                            </Typography>
                            <Typography variant='subtitle1'>
                                Search for your city of birth and select the option from the drop-down list
                            </Typography>
                        </Box>
                    </Item>
                </Grid>
                <Grid item xs={12}>
                    <Item style={{
                        display: 'flex',
                        background: 'rgb(235, 235, 235)',
                        flexDirection: 'column',
                        justifyContent: 'space-around',
                        alignItems: 'center'
                    }}>
                        <div>
                            <Geolookup
                                inputClassName="geolookup__input--nominatim"
                                disableAutoLookup={customUrl === undefined}
                                onSuggestsLookup={onSuggestsLookup}
                                onGeocodeSuggest={onGeocodeSuggest}
                                getSuggestLabel={getSuggestLabel}
                                onChange={(e) => {
                                  setPlaceNotFound("")
                                }}
                                onSuggestResults={(e)=>{
                                    // setSearchResultsDisplay(true);
                                  // console.log(e,"onSuggestResults") // this gets fired after getting valid results
                                }}
                                onSuggestNoResults={(e)=>{ // this gets fired if there are no results
                                    if(e.trim().length > 0){
                                      setPlaceNotFound(`${e} not found`)
                                      setTimeout(()=>{setPlaceNotFound("")},5000)
                                    }
                                }}
                                onSuggestSelect={(e) => {
                                    if (e.location) {
                                        //ccDefaultQuestion[questionKey] =  [e.location.lat, e.location.lon]
                                        dispatch(saveAllQuestions(questionKey, [`${e.location.lat}`, `${e.location.lon}`]))
                                        dispatch(saveAllQuestions(questionKey1, e.label))
                                        handleQuestion(questionKey1, e.label);
                                    }
                                }}
                                onButtonClick = {(e) => {console.log(e,"clk")}}
                                radius="20"
                                ref={(el)=>{}}
                            />
                        </div>
                        <div style={{
                            color: "red",
                            fontSize: "small",
                            height: "10px"
                        }}>
                        {placeNotFound}
                        </div>
                        <div
                            style={{
                                fontWeight: "bold",
                                marginBottom: "10px",
                                marginTop: "10px",
                                borderBottom: "1px solid #999999",
                                maxWidth: "800px",
                            }}
                        >
                            {placeOfBirth
                                ? placeOfBirth
                                : "<not entered>"}
                        </div>
                    </Item>
                </Grid>
            </Grid>
        </Box>
    );
}

function setSearchResultsDisplay(isShow){
      const gs = document.getElementsByClassName("geolookup__suggests-wrapper")
      if(gs && gs.length==1){
          gs[0].style.display = isShow === true ? "block" : "none";
      }
}

export default memo(GeoLocationCC);
