import { Card, CardContent, Checkbox, FormControlLabel, FormGroup, Paper, Typography } from "@mui/material";
import React from "react";
import { memo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { saveAssessmentAgreement, saveCoupleCompatibilityAgreement } from "../../redux/actions/appActions";

const CCAssessmentAgreement = () => {
    const dispatch = useDispatch();
    const agreeAccuracy = useSelector(state => state.appReducer.ccAgreeement);

    return (
        <Paper elevation={6}>
        <Card>
          <CardContent>
            <div
              className="jumbotron "
              style={{
                display: agreeAccuracy ? "none" : "block",
              }}
            >
              <Typography gutterBottom variant="h5" component="div" className='header'>
                Couple Compatibility Quiz
              </Typography>
              <Typography variant="subtitle1" color="text.primary" textAlign={'center'}>
              Be as accurate as possible to get a deeper understanding of how your relationship with a particular person is likely to pan out!
              </Typography>
              <FormGroup sx={{
                maxWidth: '620px',
                margin: '20px auto'
              }}>
                <FormControlLabel control={<Checkbox sx={{
                  color: '#b99a38',
                  color: 'linear-gradient(90deg, #b99a38, #dfc779)',
                  '&.Mui-checked': {
                    color: '#b99a38',
                    color: 'linear-gradient(90deg, #b99a38, #dfc779)',
                  }
                }} value={agreeAccuracy} onChange={() => {
                    dispatch(saveCoupleCompatibilityAgreement(true))
                //   this.setState({ showAgreement: !this.state.showAgreement })
                //   setTimeout(() => this.props.setAgreeAccuracy(true), 100)
                }} />} label="By clicking the box, I agree that if I enter incorrect info on the quiz, the AI-generated report may not be accurate." />
              </FormGroup>
            </div>
          </CardContent>
        </Card>
      </Paper>
    );
}

export default memo(CCAssessmentAgreement);
