import React, { useEffect } from "react";
import axios from "axios";
import Moment from 'moment';
import { Alert, Box, Button, FormControl, InputLabel, OutlinedInput, Typography } from "@mui/material";
import RefreshIcon from "@mui/icons-material/Refresh";
const SecurityCheck = (props) => {
    const [captchaMessage, setCaptchaMessage] = React.useState('');
    const [verificationText, setVerificationText] = React.useState();
    const [captcha, setCaptcha] = React.useState('');
    const [captchaToken, setCaptchaToken] = React.useState('');
    const [loading, setLoading] = React.useState(false);
    const [failedEvent, setFailedEvent] = React.useState(false);
    const [lockedPeriod, setLockedPeriod] = React.useState(0);
    const [attemptCount, setAttemptCount] = React.useState(0);
    const [capacityRequired, setCapacityRequired] = React.useState(1);
    
    const [securityCheckPassed, setSecurityCheckPassed] = React.useState(false);
    const onlyCharCheck = /^\d.\d+$/;
    const loadCaptcha = () => {
        axios
            .get(`${process.env.REACT_APP_SERVER}/buddy/captcha`)
            .then(response => {
                let d = response.data;
                // console.log("captcha", d);
                setCaptcha(d.image)
                setCaptchaMessage('')
                setVerificationText('');
                setCaptchaToken(d.token)
            }).catch(e => {
                //
                setVerificationText('');
                console.log("load captcha error", e);
            }).finally(()=> setLoading(false))
    }

    useEffect(() => {
        setSecurityCheckPassed(JSON.parse(localStorage.getItem('securityCheckPassed')))
        setAttemptCount(JSON.parse(localStorage.getItem('attemptCount')))
    }, [])
    useEffect(() => {
        debugger
        setCapacityRequired(props.isBothReportSelected)
    }, [props.isBothReportSelected])
    useEffect(() => {
        debugger
        if(props.showSecurityCheck) {
            loadCaptcha()
        }
    }, [props.showSecurityCheck])

    useEffect(() => {
        if(!onlyCharCheck.test(props.checksum)) {
            init(true, true);
        }
    }, [props.checksum])

    const init = (needsCaptcha, reload) => {
        setLoading(true);
       // localStorage.setItem('hardLock', false)
        axios
            .post(`${process.env.REACT_APP_SERVER}/quizopen/isHardLockout`, { checksum: props.checksum })
            .then((response) => {
                const { status, ttl } = response.data;
                //console.log('data => ', d)
                if (!status) {
                    
                    if(JSON.parse(localStorage.getItem('hardLock'))) {
                        debugger;
                        localStorage.setItem('attemptCount', 0)
                        localStorage.setItem('hardLock', false)
                        lockedPeriod(0)
                        setSecurityCheckPassed(false)
                    }
                    axios
                        .post(`${process.env.REACT_APP_SERVER}/quizopen/isSoftLockout`, { checksum: props.checksum })
                        .then((response) => {
                            
                            const { status, ttl } = response.data;
                            
                            if (!status) {
                              
                              //  
                                if(needsCaptcha) {
                                 //   localStorage.setItem('attemptCount', 0)
                                    loadCaptcha()
                                }
                            } else {
                               // if (reload){
                                    setLockedPeriod(Moment().add(ttl, 'seconds').format('MMM DD, YYYY hh:mm:ss A'))
                                //}
                                setSecurityCheckPassed(false);
                                localStorage.setItem('securityCheckPassed', false)
                                
                            }
                        }).catch(e => {
                            setCaptchaMessage('Error occured, please reload the page');
                            // setSecurityCheckPassed(false);
                            // localStorage.setItem('securityCheckPassed', false)
                            console.log("Soft lock error", e);
                        }).finally(()=> setLoading(false))
                } else {
                    setLoading(false);
                   // if(reload) {
                        setLockedPeriod(Moment().add(ttl, 'seconds').format('MMM DD, YYYY HH:mm:ss A'))
                   // }
                   setSecurityCheckPassed(false);
                   localStorage.setItem('securityCheckPassed', false)
                    localStorage.setItem('hardLock', true)
                    
                }
                
            })
            .catch((e) => {
                setLoading(false);
                // setSecurityCheckPassed(false);
                // localStorage.setItem('securityCheckPassed', false)
                // localStorage.setItem('hardLock', true)
                setCaptchaMessage('Error occured, please reload the page');
                console.log("Hard lock error", e);
            });
    }


    const confirmSecurityCheck = () => {
setLoading(true)
        setCaptchaMessage('')
        axios
            .post(`${process.env.REACT_APP_SERVER}/quizopen/validateCaptcha`, {
                checksum: props.checksum,
                captcha_token: captchaToken,
                capacity_required: capacityRequired ? 2:1,
                validation_text: verificationText,
            })
            .then((response) => {
                    //localStorage.setItem('attemptCount', 0)
                    setSecurityCheckPassed(true);
                    props.returnCallback(false);
                    localStorage.setItem('securityCheckPassed', true)
                    //setLockedPeriod(Moment().add(ttl, 'seconds').format('lll'))
            }).catch(e => {
                setCaptchaMessage(e?.response?.data?.message)
                let needsCaptcha = false;
                const count = JSON.parse(localStorage.getItem('attemptCount'))
                localStorage.setItem('attemptCount', Number(count) + 1)
                setAttemptCount(Number(count) + 1)
                if (e.response.status === 403 && e.response.data.message.includes('User is lockedout')) {
                    needsCaptcha = true
                   // localStorage.setItem('hardLock', true)
                    //loadCaptcha()
                }
                init(needsCaptcha);
                console.log("Soft lock error", e);
            })

    }
    
    return (
        <Box>
            {
                 !securityCheckPassed || props.showSecurityCheck ?
                    (
                        <div className='maincontent'>
                            {
                                lockedPeriod ?
                                    <Box sx={{
                                        display: 'flex',
                                        flexDirection: 'column',
                                        alignItems: 'center'
                                    }}>
                                        <Typography variant="body2">
                                            {'Due to multiple failed CAPTCHA attempts, come back again after ' + lockedPeriod}
                                        </Typography>
                                    </Box>
                                    :
                                    <Box sx={{
                                        display: 'flex',
                                        flexDirection: 'column',
                                        alignItems: 'center'
                                    }}>
                                        <Box>
                                            <Typography variant="body2">
                                                As a protection against spam, type in the letters that appears in this image.
                                            </Typography>
                                        </Box>
                                        <Box display="grid" idTemplateColumgrns="repeat(12, 1fr)" gap={2} sx={{
                                            display: 'flex',
                                            alignItems: 'center',
                                            justifyContent: 'space-around',
                                            padding: '16px'
                                        }} className="make-portrait">
                                            <Box gridColumn="span 12">
                                                {captcha ? (
                                                    <img src={`data:image/png;base64,${captcha}`} className="capcha-img" />
                                                ) : (
                                                    ""
                                                )}
                                            </Box>
                                            <Box gridColumn="span 12">
                                                <FormControl sx={{ m: 1, width: '25ch' }} variant="outlined">
                                                    <InputLabel htmlFor="outlined-adornment-password">Type in the words</InputLabel>
                                                    <OutlinedInput
                                                        id="outlined-adornment-password"
                                                        value={verificationText}
                                                        onChange={(e) => {
                                                            // this.setState({
                                                            //   verificationText: e.target.value.toUpperCase(),
                                                            // });
                                                            setVerificationText(e.target.value.toUpperCase());
                                                        }}
                                                        size={"medium"}
                                                        endAdornment={
                                                            <a href="#">
                                                                <RefreshIcon
                                                                    onClick={() => {
                                                                        loadCaptcha();
                                                                    }}
                                                                />
                                                            </a>
                                                        }
                                                        label="Type in the words"
                                                    />
                                                </FormControl>

                                            </Box>
                                            <div>
                                                {captchaMessage ? (
                                                    <Alert severity="error">{captchaMessage}</Alert>
                                                ) : (
                                                    ""
                                                )}
                                            </div>
                                        </Box>
                                        <Box>
                                            <Button sx={{
                                                color: '#fff',
                                                textTransform: 'none',
                                                backgroundColor: 'FEA133',
                                                background: 'linear-gradient(90deg, #b99a38, #dfc779)',
                                                // border: '4px solid #fff'
                                                margin: '0 auto'
                                            }} variant="contained" disabled={!verificationText} onClick={(e) => {
                                                //cc_payload = null;
                                                confirmSecurityCheck();
                                            }}>
                                                Submit
                                            </Button>
                                        </Box>
                                        {attemptCount > 3 && !loading && 
                                            <Box sx={{mt: 2}}>
                                                <Typography variant="body2">
                                                    {`After the next wrong CAPTCHA, the quiz will lock for ${attemptCount === 4 ? '3 mins' : '12 hours'}`}
                                                </Typography>
                                            </Box>
                                        }
                                    </Box>
                            }
                        </div>
                    )
                    :
                    props.children
            }
        </Box>
    )
}

export default SecurityCheck