import React from "react";
import ReactDOM from "react-dom";
import AppV2 from "./AppV2";
import { BrowserRouter } from "react-router-dom";
import store from './store'
import { Provider } from "react-redux";
import { Route } from "react-router-dom";
import { Suspense } from "react";
import Loader from "./components/v2/Loader";

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
    <Suspense fallback={<Loader />}>
      <BrowserRouter>
        <Route path="/" component={AppV2} />
      </BrowserRouter>
      </Suspense>
    </Provider>
  </React.StrictMode>,
  document.getElementById("root")
);
