import * as React from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';

const Loader = () => {

    return (
        <Box sx={{ 
            position: 'relative',

            width: '100vw',
                    height: '100vh'
         }}>
            <Box sx={{
                    position: 'absolute',
                    transform: 'translate(-50%, -50%)',
                    left: '50%',
                    top: '50%',
                    
            }}>
                <CircularProgress />
            </Box>
            
        </Box>
    );
}

export default Loader;