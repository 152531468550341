export const actionTypes = {
    SAVE_QUESTIONS: 'SAVE_QUESTIONS',
    READ_QUESTIONS: 'READ_QUESTIONS',
    SAVE_PURCHASE_REPORTS_DATA: 'SAVE_PURCHASE_REPORTS_DATA',
    UPDATE_PURCHASE_REPORTS_DATA: 'UPDATE_PURCHASE_REPORTS_DATA',
    SAVE_TRIAL_REPORTS_DATA: 'SAVE_TRIAL_REPORTS_DATA',
    SAVE_COMPUTATION: 'SAVE_COMPUTATION',
    SAVE_TRIAL: 'SAVE_TRIAL',
    SAVE_AFFILIATOR_CODE: 'SAVE_AFFILIATOR_CODE',
    SAVE_NA_QUESTIONS: 'SAVE_NA_QUESTIONS',
    SAVE_QUESTIONS_TO_LOCAL: 'SAVE_QUESTIONS_TO_LOCAL',
    SAVE_ASSESSMENT_AGREEMENT: 'SAVE_ASSESSMENT_AGREEMENT',
    SAVE_COUPLE_COMPATIBILITY_AGREEMENT: 'SAVE_COUPLE_COMPATIBILITY_AGREEMENT',
    TOGGLE_OPTIONAL_QUESTIONS: 'TOGGLE_OPTIONAL_QUESTIONS',
    SAVE_MAINTENANCE_DATA: 'SAVE_MAINTENANCE_DATA',
    SAVE_STATIC_QUESTION: 'SAVE_STATIC_QUESTION',
    SAVE_DYNAMIC_QUESTIONS: 'SAVE_DYNAMIC_QUESTIONS',
    UPDATE_DYNAMIC_QUESTIONS: 'UPDATE_DYNAMIC_QUESTIONS',
    DELETE_DYNAMIC_QUESTIONS: 'DELETE_DYNAMIC_QUESTIONS',
    SAVE_PERSON1_QUESTIONS: 'SAVE_PERSON1_QUESTIONS',
    SAVE_PERSON2_QUESTIONS: 'SAVE_PERSON2_QUESTIONS'
}