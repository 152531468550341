import { Box, Checkbox, FormControlLabel, Grid, Paper, Typography } from "@mui/material";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { ccDefaultQuestion, defaultQuestion, Global } from "../../global";
import { saveAllQuestions, saveComputeData, updateDynamicQuestion } from "../../redux/actions/appActions";
import { styled } from '@mui/material/styles';
import { useEffect } from "react";
import { useState } from "react";

const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'center',
    height: 150,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    position: 'relative',
    color: theme.palette.text.primary,
  }));

const CcGridComponent = ({question,questionKey, isPinkBackground, handleQuestion}) => {

    const dispatch = useDispatch();
    const defaultQuestionSet = ccDefaultQuestion();
    const [currentData, setCurrentData] = useState(null)
    // const currentQuestionData = useSelector(state => state.appReducer[questionKey]);
   // const questionData = questionData//.sortBy((d,k)=>  /\s/g.test(k)).toJS();
   useEffect(()=> {
    setCurrentData(question)
   }, [question])

    return currentData ? (
        <Box className="grid-container">
            <Grid container spacing={2} direction="row" justifyContent="center" alignItems="center">
                <Grid item xs={12} zeroMinWidth>
                    <Item style={{ background: isPinkBackground ? '#FE90AA' : '#FA6C6C'  }}>
                        <Box>
                            <Typography variant="h5">
                                {currentData.question}
                            </Typography>
                            {currentData.tip && <Typography variant='subtitle1'>
                                {currentData.tip}
                            </Typography>
                            }
                        </Box>
                    </Item>
                </Grid>
                <Grid item xs={12}>
                    <Grid container spacing={{ xs: 2, md: 3 }} columns={{ xs: 4, sm: 8, md: 12 }} direction="row" justifyContent="center" alignItems="center">
                        {
                        Array.isArray(currentData.choices) ?
                            currentData.choices.map((option, index)=>(
                                <Grid item xs={6} sm={4} md={3} key={index} className="grid-item--container" >
                                    <Item style={{
                                        padding: 0,
                                        border: currentData.answer === option ? '5px solid #b99a38' : 'none',
                                    }} htmlFor={`${option}`} className="grid-item">
                                        <Box className='answer-snap' sx={ {
                                            'label.MuiFormControlLabel-root' :  {
                                                padding: 0,
                                                height: '100%',
                                                width: '100%',
                                                display: 'flex !important',
                                                justifyContent: 'center',
                                                border: '1px solid',
                                                borderColor: '#fff'
                                            }
                                        }} >
                                            <FormControlLabel className='answer-label'
                                                control={
                                                    <Checkbox checked={`${currentData.answer === option}`} onClick={(e) => {
                                                        // debugger;
                                                        // let updatedQuestions = currentData;
                                                        // if ((option === Global.NoneOfTheAbove && e.target.checked) || !isMultipleType) {
                                                        //     updatedQuestions = defaultQuestionSet[questionKey]
                                                        // } else {
                                                        //     if (updatedQuestions[Global.NoneOfTheAbove] === true) {
                                                        //         updatedQuestions[Global.NoneOfTheAbove] = false;
                                                        //     }
                                                        // }
                                                        // updatedQuestions[option] = e.target.checked;
                                                        // setCurrentData({...updatedQuestions})
                                                        // dispatch(saveAllQuestions(questionKey, updatedQuestions))
                                                        // dispatch(saveComputeData());


                                                        let updatedQuestion = currentData;
                                                        updatedQuestion.answer = option
                                                        handleQuestion(question.qid, option);
                                                        //setCurrentData(updatedQuestion)
                                                        //updatedQuestions[option] = e.target.checked;
                                                        setCurrentData({...updatedQuestion})
                                                        dispatch(updateDynamicQuestion(questionKey, updatedQuestion))
                                                    }} name={`${option}`} color="success" className='answer-checkbox' />
                                                }
                                                label={<Typography variant="subtitle1" className='answer-text' color={'text.primary'}>{option}</Typography>}
                                            />
                                        </Box>
                                    </Item>
                                </Grid>
                            )) :
                        
                        Object.keys(currentData.choices).map((option, index) => (
                            <Grid item xs={6} sm={4} md={3} key={index} className="grid-item--container" >
                                <Item style={{
                                    padding: 0,
                                    border: currentData.answer === index ? '5px solid #b99a38' : 'none',
                                }} htmlFor={currentData.choices[option]} className="grid-item">
                                    <Box className='answer-snap' sx={ {
                                         'label.MuiFormControlLabel-root' :  {
                                            padding: 0,
                                            height: '100%',
                                            width: '100%',
                                            display: 'flex !important',
                                            justifyContent: 'center',
                                            border: '1px solid',
                                            borderColor: '#fff'
                                         }
                                    }} >
                                        <FormControlLabel className='answer-label'
                                            control={
                                                <Checkbox checked={`${currentData.answer === index}`} onClick={(e) => {
                                                    // debugger;
                                                    // let updatedQuestions = currentData;
                                                    // if ((option === Global.NoneOfTheAbove && e.target.checked) || !isMultipleType) {
                                                    //     updatedQuestions = defaultQuestionSet[questionKey]
                                                    // } else {
                                                    //     if (updatedQuestions[Global.NoneOfTheAbove] === true) {
                                                    //         updatedQuestions[Global.NoneOfTheAbove] = false;
                                                    //     }
                                                    // }
                                                    let updatedQuestion = currentData;
                                                    updatedQuestion.answer = index
                                                    //setCurrentData(updatedQuestion)
                                                    //updatedQuestions[option] = e.target.checked;
                                                    setCurrentData({...updatedQuestion})
                                                    handleQuestion(question.qid, `${index}`);
                                                    dispatch(updateDynamicQuestion(questionKey, updatedQuestion))
                                                    //dispatch(saveComputeData());
                                                }} name={currentData.choices[option]} color="success" className='answer-checkbox' />
                                            }
                                            label={<Typography variant="subtitle1" className='answer-text' color={'text.primary'}>{  currentData.choices[option]}</Typography>}
                                        />
                                    </Box>
                                </Item>
                            </Grid>
                        ))}
                    </Grid>
                </Grid>
            </Grid>
        </Box>
    ) : null;
}

export default React.memo(CcGridComponent);
