import React from 'react';
import { Box } from '@mui/material';

const BuddyNowImg = ({src, style, children}) => {
    return (
        <Box
            component="img"
            id="buddy-now-logo"
            sx={style ? style : {
                height: 128,
                width: 128,
                maxHeight: { xs: 128 },
                maxWidth: { xs: 128 },
            }}
            alt="Buddy Now logo"
            src={src}
        >
{children}
        </Box>
    );
}

export default BuddyNowImg

